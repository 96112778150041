@import "./uploadDocumentCard/uploadDocumentCard";

.thank-you-page-pdv {
  .thank-you-page-pdv-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100dvh - 50px); // fullscreen - header
    padding: 24px 16px;
    max-width: 598px;
    margin: 0 auto;
  }

  .text-section {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    color: #222222;
  }

  .success-icon-wrapper {
    align-self: center;
  }

  .thank-you-page-pdv-title {
    font:
      800 20px "Muli",
      sans-serif;
    margin: 0;
  }

  .thank-you-page-pdv-description {
    font:
      400 16px / 20px "Roboto",
      sans-serif;
    margin: 0;
  }

  .upload-document-note {
    border-left: 2px solid #ffbf00;
    padding-left: 6px;
    font:
      12px / 14px "Roboto",
      sans-serif;
  }

  .card-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

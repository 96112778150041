.userData {
  .suggest-btn {
    @include sisal-box-sizing(border-box);
    height: 1.94rem;
    width: auto;
    border: 0.06rem solid $suggestBtn;
    border-radius: 1rem;
    opacity: 0.8;
    color: $textSelectColor;
    margin-left: 0.81rem;
    margin-right: 0.44rem;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1rem;
    margin-top: 0.3125rem;
    margin-bottom: 0.625rem;
    @include medium-devices-tablet {
      margin-bottom: 0.94rem;
      margin-left: 0.94rem;
    }
  }

  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
  .email-btn {
    height: 35px;
    max-width: 100%;
    border-radius: 1.56rem;
    margin-right: 0.56rem;
    border: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-left: 0.94rem;
    margin-bottom: 10px;

    &.not-selected {
      background-color: #f2f2f2;
      border-radius: 1rem;
      color: black;
    }
  }
  .fake-tooltip-title {
    height: 1.188rem;
    color: #ff3c45;
    font-size: 12px;
    line-height: 1.188rem;
    width: 100%;
    margin-top: -0.125rem;
    font-weight: 500;
    margin-left: 0.31rem;
    @include medium-devices-tablet {
      margin-bottom: 1rem;
      margin-left: 0.94rem;
    }
  }

  .btn:focus {
    @include sisal-box-shadow(none);
  }
  .width-password {
    max-width: 42.5rem;
  }

  ::-ms-reveal {
    display: none !important;
  }

  .error-box {
    .invalid-feedback {
      display: block;
    }

    &.is-required {
      .invalid-feedback {
        color: #e32219;
      }
    }
  }

  .validation-check-container {
    max-width: 720px;
    margin-top: 10px;
    margin-bottom: 25px;

    .check-container {
      flex-grow: 1;
    }

    .check-topic {
      color: #3a3a3a;
      font-weight: 300;
      margin-top: 4px;
      margin-bottom: 0px;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      min-width: 100%;
      color: #2f3c43;
      margin-right: 0.625rem;
      padding-right: 1.25rem;

      &.success {
        font-weight: 500;
        color: #0a7d41;
        background: url("#{$images-path}/svg/icon/simple-check.svg");
        @include sisal-background-size(0.81rem);
        background-repeat: no-repeat;
        background-position: right;
        @include ie11support {
          @include sisal-background-size(0.8rem 0.7rem);
        }
      }

      &.not-success {
        font-weight: 500;
        color: $primaryErrorColor;
        background: url("#{$images-path}/svg/icon/simple-check-error.svg");
        @include sisal-background-size(0.81rem);
        background-repeat: no-repeat;
        background-position: right;
        @include ie11support {
          @include sisal-background-size(0.8rem 0.7rem);
        }
      }
    }
  }

  .username-input-container {
    margin-bottom: 4px;
  }
}

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

input.key {
  font-family: "password", Roboto, sans-serif;
  width: 100px;
  height: 16px;
}

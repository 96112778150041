.document-selection-container {
  .document-selection-nav {
    display: flex;
    justify-content: space-between;

    &__next {
      border: none;
    }
  }
}

// /* .Button Icon */

// box-sizing: border-box;

// /* Auto layout */
// display: flex;
// flex-direction: row;
// justify-content: center;
// align-items: center;
// padding: 6px;

// width: 36px;
// height: 36px;

// /* Neutral/white */
// background: #ffffff;
// /* Primary/light green

// CTA principali, link
// */
// border: 1px solid #bed62e;
// border-radius: 8px;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

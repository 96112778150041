.calculate-fiscal-code-popup {
  .popup-modal {
    display: flex;
    flex-direction: column;

    @include medium-devices-tablet {
      height: unset;
    }
  }

  .calculate-fiscal-code-popup-body {
    flex: 1 0 auto;

    @include medium-devices-tablet {
      height: 300px;
      overflow: auto;
    }
  }

  .popup-modal-footer {
    button {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .popup-modal-body {
    height: 100%;
  }

  .birthplace {
    padding: 0;

    .px-sm-0 {
      @include landscape-mobile {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }

      @include medium-devices-tablet {
        padding-left: unset !important;
        padding-right: unset !important;
      }

      @include desktop {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    @include desktop {
      .col-lg-6 {
        flex: unset;
        max-width: unset;
      }

      .offset-lg-3 {
        margin-left: unset;
      }
    }
  }

  .overlay {
    &.popup-modal-overlay {
      .popup-modal {
        .popup-modal-body {
          padding-bottom: 0;
        }
      }
    }
  }
}

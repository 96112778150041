.spid-container {
  display: flex;
  flex-direction: column;
  max-width: 806px;
  margin: 0 auto;
  padding-top: 32px;

  .title-form {
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .card-spid-wrapper-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    @include medium-devices-tablet {
      flex-direction: row-reverse;
    }
  }

  .card-spid-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    padding: 16px;
    box-shadow: 0px 4px 16px 0px #0000001f;
    border-radius: 10px;
    background-color: white;
    cursor: pointer;

    &--classic {
      border: 1px solid #0a7d41;
    }

    &--register-with-spid {
      border: 1px solid #0066cc;
    }
  }

  .spid-wrapper-title {
    font:
      700 16px "Muli",
      sans-serif;
    padding-bottom: 12px;
  }

  .spid-wrapper-info {
    font:
      14px/18px "Roboto",
      sans-serif;
    padding-bottom: 16px;
  }

  .spid-wrapper-button {
    display: flex;
    align-self: flex-end;
    margin-top: auto;
  }

  .card-label {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #0b7d3e;
    font:
      500 12px "Roboto",
      sans-serif;
    color: white;
    padding: 5px 12px;
    border-radius: 0 8px 0;
  }

  .registration-button {
    font:
      700 14px "Roboto",
      sans-serif;
    color: #262c2f;
    height: 40px;
    border-radius: 8px;
    padding: 0 16px;
    white-space: nowrap;

    &--primary {
      background: #bed62f;
      border: 1px solid rgba(34, 34, 34, 0.12);
    }

    &--secondary {
      border: 1px solid #bed62f;
    }
  }

  .title-form {
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    color: #222;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .subtitle-form {
    font:
      400 14px/18px "Roboto",
      sans-serif;
    color: #222;
    margin-bottom: 24px;
  }

  .header-form {
    margin-bottom: 0px;
  }

  .info-privacy-container {
    padding-top: 24px;
  }

  .link-pdv-container {
    box-shadow: unset;
    border-color: #d3d3d3;
  }
}

@import "./errorCard/errorCard";

.error-card-ocr-page {
  .error-card-container {
    height: calc(100dvh - 50px);
    padding: 32px 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include medium-devices-tablet {
      height: unset;
    }
  }
}

@import "./activeAccountBtnBack/activeAccountBtnBack";

.activate-account-page {
  .info-privacy-disclaimer {
    margin: 0 auto;
    padding: 32px 15px 0;

    @include landscape-mobile {
      max-width: 525px;
      padding-left: 0;
      padding-right: 0;
    }

    @include medium-devices-tablet {
      max-width: 570px;
    }
  }
}

.activeAccount {
  .sub-title-form {
    padding-top: 18px;
  }

  .card-active {
    @include sisal-box-shadow(0 0.125rem 1.25rem 0 $boxShadowGrey);
    border-radius: 0.5rem;
    background-color: $whiteColor;
    padding: 16px 24px 16px;
    border-style: solid;
    border-right-color: $borderYellow;
    border-width: 0 8px 0 0;

    @include medium-devices-tablet {
      margin-right: unset;
      border-right-width: 0.625rem;
    }

    @include landscape-mobile {
      margin-right: 16px;
    }

    .img-card-sisal {
      height: 20px;
      vertical-align: top;
      @include medium-devices-tablet {
        height: 20px;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .img-card-attivazione {
      width: 149px;
      height: 28px;
    }

    .form-group {
      min-height: unset;
      margin-bottom: 16px;

      @include landscape-mob {
        margin-bottom: 16px;
      }

      &.form-group-card {
        .form-control {
          border: 1px solid #d4d5d5;
          height: 2.88rem;
          font-size: 16px;
          font-weight: 400;
          color: #262c2f;
          font-family: Roboto, sans-serif;
          padding-left: 16px;

          &:focus ~ label {
            font-weight: 500;
          }

          &.is-invalid {
            border: 0.1rem solid $primaryErrorColor;
          }

          &.is-present {
            border: 0.1rem solid $textAlreadyPresent;

            ~ .invalid-feedback .invalid-alert {
              padding: 0;
            }
          }

          &.is-invalid-yellow {
            background: url("#{$images-path}/svg/icon/alert-orange.svg")
              no-repeat right 0.75rem center/1.375rem 1.375rem;

            @include medium-devices-tablet {
              background: url("#{$images-path}/svg/icon/alert-orange.svg")
                no-repeat right 0.75rem center/1.5rem 1.5rem;
            }

            ~ label {
              color: $textAlreadyPresent;
              font-weight: 500;
            }

            ~ .bar {
              &:before {
                height: 0.125rem;
                background: $textAlreadyPresent;
                @include sisal-box-shadow(none);
              }
            }

            border: 0.125rem solid $textAlreadyPresent;
          }

          label {
            padding-left: 9px;
          }

          label.form-control-label.disable {
            background: none !important;
          }

          .invalid-feedback {
            padding: 0;
            font-size: 0.8rem;
            position: relative;

            #messagio_errore_fail {
              margin-bottom: 0px !important;
            }
          }
        }
      }
    }

    .form-control-label {
      color: #929596;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    h5 {
      span.h-light {
        display: block;
        font-weight: 100;
      }
    }
  }

  .row {
    margin-right: 0;
  }

  .widthCard {
    min-width: 326px;
    min-height: 280px;
    @include medium-devices-tablet {
      max-width: 21.69rem;
    }
    @include landscape-mob {
      min-height: 240px;
    }
  }
  .infoCardActiveAccount {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include medium-devices-tablet {
      margin-left: 16px;
      padding-left: 0;
    }
  }

  .textBackPdv {
    color: #222222;
    font-size: 14px;
    font-weight: bold;
    margin-top: 11px;
  }

  .text-pdv-right {
    margin-bottom: 0px;
    color: #222222;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;

    @include landscape-mob {
      margin-top: 16px;
    }

    &.down {
      margin-top: 16px;

      @include medium-devices-tablet {
        margin-top: 16px;
      }
    }

    &.soft {
      font-weight: 400;
    }
  }

  .text-error-pdv {
    font-size: 0.8rem;
    margin-top: 1rem;
    font-weight: 500;
  }

  .text-error-pdv-generic {
    color: $textAlreadyPresent;
  }

  .text-error-pdv-mismatch {
    color: $primaryErrorColor;
  }
  .marginBot {
    padding-bottom: 20px;

    @include landscape-mob {
      padding-bottom: 0;
    }
  }

  .btn-link {
    height: auto;
    border-radius: 0;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 0.875rem;

    @include medium-devices-tablet {
      line-height: normal;
    }

    .icon-btn-left {
      line-height: inherit;

      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .is-not-center {
    padding-right: 0 !important;
  }

  .btn.btn-icon-right {
    padding: 0px 8px 0px 16px;
    gap: 8px;
    border-radius: 8px;
    height: 40px;
    width: auto;

    .icon-btn-right {
      margin: 0;
      @include landscape-mobile {
        margin: 0;
        margin-left: 0.94rem;
      }

      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    @include landscape-mobile {
      margin-right: 1.125rem;
      padding-left: 2rem;
      padding-right: 0.7rem;
      gap: 8px;
      border-radius: 8px;
      height: 40px;
      width: auto;
    }

    span {
      text-align: center;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }
  }
}

.nation {
  &.select-arrows {
    &:after {
      // content: '';
      background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
        right 0.2rem center/1.125rem 1.125rem;
      position: absolute;
      right: 7px;
      top: 0.625rem;
      width: 24px;
      height: 24px;
    }
  }
  .flag-nation-input {
    position: absolute;
    left: 0.875rem;
    width: 100%;
    display: block;
    top: 0.6rem;
    height: 1.25rem;
    max-width: 1.81rem;
    @include medium-devices-tablet {
      top: 0.6rem;
      height: 1.56rem;
      max-width: 2.19rem;
    }
  }
  .form-control {
    &.notExistFlag {
      padding-left: 0.31rem;
      background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
        right 0.55rem center/18px white;
    }
    &.existFlag {
      padding-left: 3.44rem;
      background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
        right 0.55rem center/18px white;
    }
  }
  .flag-nation-select {
    width: 100%;
    left: 1.06rem;
    display: inline-block;
    margin-right: 0.625rem;
    height: 17px;
    width: 24px;
    margin-right: 0.31rem;
  }

  .opened-nation-list {
    max-height: 170px;
    position: absolute;
    // top: 3.313rem;
    z-index: 2;
    width: 100%;
    overflow-y: auto;
    border-radius: 4px;
    background-color: $primaryBgColor;
    color: $textSelectColor;
    @include sisal-box-shadow(0 0.06rem 0.19rem 0 $boxShadowGrey);
    font-weight: 300;
    cursor: default;
    font-size: 1rem;
  }

  .no-border {
    border: none;
  }
  .selected {
    background-color: $selectNation;
  }
  .list-group-item {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    color: #222222;
    font-weight: 400;
    font-size: 12px;
    @include medium-devices-tablet {
      font-size: 12px;
    }
    @include ie11support {
      min-height: 3.5rem;
    }
  }
}

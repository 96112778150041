@import "./popupModalTransitions";

.popup-modal-overlay {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  background: rgba(34, 34, 34, 0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @include medium-devices-tablet {
    align-items: center;
  }

  .popup-modal {
    width: 100%;
    max-width: 560px;
    border-radius: 10px 10px 0px 0px;
    background: #ffffff;

    @include medium-devices-tablet {
      border-radius: 10px;
    }
  }

  .popup-modal-header {
    height: 52px;
    width: 100%;
    padding: 14px 16px;
    font-weight: 800;
    display: flex;
    justify-content: space-between;
    border-radius: 10px 10px 0px 0px;
    background: #2f3c43;
    color: white;
  }

  .popup-modal-header-title {
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
  }

  .popup-modal-close {
    cursor: pointer;
  }

  .popup-modal-body {
    max-height: calc(100vh - 124px);
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    color: #222222;
    padding: 16px 16px 24px;
    overflow: auto;

    &.hide-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Chrome, Safari and Opera */
      }
    }

    @include medium-devices-tablet {
      max-height: min(calc(90vh - 124px), 700px);
      padding-top: 24px;
    }
  }

  .popup-modal-footer {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    box-shadow: inset 0px 0.5px 0px #d9d9d9;

    & > button {
      width: 100%;
      padding: 9px 50px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      background-color: #bed62f;
      color: #262c2f;
      font-weight: 900;
      font-size: 14px;
      line-height: 22px;
      text-align: center;

      &:not(:last-child) {
        margin-right: 16px;
      }

      &.outline-button {
        background-color: white;
        border: 1px solid #bed62f;
      }

      &:disabled,
      &[disabled] {
        background-color: #def199;
        color: #9b9b9b;
      }
    }

    @include medium-devices-tablet {
      box-shadow: none;
      border-radius: 0;

      & > button {
        width: unset;
      }
    }
  }
}

.spid-registration-container {
  display: flex;
  flex-direction: column;
  max-width: 672px;
  margin: 0 auto;
  padding-top: 15vh;

  .spid-registration-title {
    font-family: Muli;
    font-style: normal;
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .card-spid-registration-wrapper {
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 672px;
    border: 1px solid #f0f2f4;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    background-color: white;

    .spid-registration-wrapper-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 8px;
    }

    .spid-registration-text {
      font-family: Roboto;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      padding-bottom: 6px;
    }

    .spid-registration-info {
      &-plus {
        padding-bottom: 6px;
      }

      a {
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        color: $textSelectColor;
        line-height: initial;
      }
    }

    .spid-registration-button {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 26px;
    }

    .spid-registration-logo {
      align-self: center;
      padding-top: 25px;
    }
  }
}

.spid-registration-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 8px;
  width: 672px;
  height: 32px;
  margin: auto;
  background: #f5f5f5;
  border-radius: 5px;
  margin-top: 32px;
  text-transform: uppercase;

  &-logo {
    align-self: end;
  }

  span {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    color: #929596;

    img {
      padding-right: 16px;
    }
  }
}

// device-responsivness
@media screen and (max-width: 768px) {
  .spid-registration-container {
    padding-top: 16px;

    .card-spid-registration-wrapper {
      width: 100%;

      .spid-registration-button {
        padding-top: 48px;
      }

      .spid-registration-text {
        font-size: 12px;
      }
    }
  }

  .spid-registration-footer {
    width: 100%;
    border-radius: unset;
  }
}

.promo-popup {
  .promo-popup-body-title {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    .promo-popup-body-title-logo-box {
      margin-right: 0;
      margin-bottom: 8px;

      &.promo-popup-body-title-logo-box-success {
        margin-bottom: 16px;
      }
    }

    .promo-popup-body-title-text {
      font:
        700 16px / 16px "Muli",
        sans-serif;
      align-self: flex-start;
    }

    @include medium-devices-tablet {
      &.promo-popup-body-title-success {
        flex-direction: column;

        .promo-popup-body-title-text {
          margin-top: 8px;
          align-self: flex-start;
        }
      }
    }
  }

  .promo-popup-body-bonus {
    margin-bottom: 8px;

    .form-group {
      min-height: unset;
    }

    &.bonus-is-valid {
      margin-bottom: 0px;
    }
  }

  .promo-popup-body-note {
    margin: 8px 0 0;
    padding-left: 6px;
    padding-right: 8px;
    border-left: 2px solid #ffaf1e;
    font-size: 12px;
    line-height: 14px;

    @include medium-devices-tablet {
      margin-bottom: 8px;
    }
  }
}

.error-card-bonus-page {
  .preFooter {
    position: absolute;
    bottom: 11px;

    @include medium-devices-tablet {
      position: unset;
    }
  }
}

.errorPage {
  padding-top: 3.125rem;
  font-size: 0.875rem;

  z-index: 1;
  position: relative;
  background-color: white;

  @include medium-devices-tablet {
    font-size: 1.125rem;
  }
  color: $primaryTextColor;
  font-weight: 300;
  .error-bg {
    margin-top: -3.125rem;
    min-height: 10.63rem;
    height: 25vw;
    background: url("#{$images-path}/svg/errorPage/bg.svg") no-repeat center top;
    background-size: cover;
    .lifebelt {
      background: url("#{$images-path}/svg/errorPage/lifebelt.svg") no-repeat
        70% bottom;
      background-size: 200px;
      height: 100%;

      @include medium-devices-tablet {
        background-size: 250px;
      }
      @include desktop {
        background-size: 350px;
      }
    }
  }
  .container {
    .row {
      margin-top: 2.06rem;
      font-size: 1.5rem;
      .col-md-6 {
        height: 3.75rem;
        margin-bottom: 1.56rem;
        @include medium-devices-tablet {
          height: 5rem;
        }
        &.chatCol p {
          cursor: pointer;
        }
        .contactIcon {
          height: 3.75rem;
          width: 3.75rem;
          border-radius: 50%;
          margin-right: 1rem;
          display: inline-block;
          @include sisal-linear-double-gradient(
            90deg,
            $gradientsGreenLight,
            0%,
            $gradientsGreenDark,
            100%
          );
          @include medium-devices-tablet {
            height: 5rem;
            width: 5rem;
          }
          img {
            margin: 0.625rem;
            position: absolute;
            width: 2.5rem;
            @include medium-devices-tablet {
              width: 3.75rem;
            }
          }
        }
        p {
          display: inline-block;
          line-height: 1;
          height: 3.75rem;
          margin: 0;
          @include medium-devices-tablet {
            height: 5rem;
          }
          &.contact-caption {
            vertical-align: top;
            display: inline-block;
            @include medium-devices-tablet {
              vertical-align: text-bottom;
            }
            a {
              color: $primaryTextColor;
            }
            .contactTitle {
              font-size: 0.77rem;
            }
            &.callInfo {
              @include medium-devices-tablet {
                pointer-events: none;
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
}

.wizard-ocr-summary-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  max-width: 500px;
  margin: 0 auto;

  @include landscape-mobile {
    padding: 0;
  }

  .wizard-ocr-summary-footer__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
    padding: 0 16px;
    border-radius: 8px;
    color: #222;
    height: 36px;
    font:
      700 14px/18px Roboto,
      sans-serif;
  }

  .wizard-ocr-summary-footer__button--back {
    border: 1px solid #bed62f;
    background-color: white;
  }

  .wizard-ocr-summary-footer__button--continue {
    background-color: #bed62f;
    border: 1px solid rgba(34, 34, 34, 0.12);

    &:disabled {
      background: #f2f7d5;
      color: #909090;

      .wizard-ocr-summary-footer__icon-next {
        filter: invert(62%) sepia(4%) saturate(15%) hue-rotate(332deg)
          brightness(91%) contrast(91%);
      }
    }
  }

  .wizard-ocr-summary-footer__icon-next {
    width: 13px;
  }
}

.summary-container {
  .password-row {
    display: flex;
    justify-content: space-between;
  }

  .password-row__eye-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
  }

  .popup-access-data-body {
    padding-top: 8px;

    .header-form {
      display: none;
    }

    .userData {
      padding: 0;
    }

    #passPopover {
      margin-bottom: 0 !important;
    }
  }
}

@import "./confirmDataBlock/confirmDataBlock";

.unmatching-personal-data {
  .main-content {
    max-width: 518px;
    padding: 16px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @include medium-devices-tablet {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .errorCardContainer {
    color: #030303;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto 24px;

    .logo {
      margin-bottom: unset;
    }

    .title {
      font-size: 18px;
      margin-bottom: unset;
    }

    .text {
      font-size: 14px;
      margin-bottom: unset;
    }

    @include medium-devices-tablet {
      width: unset;
      max-width: unset;
    }
  }

  .form-subtitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  @include desktop {
    .birthplace {
      padding: unset;
    }

    .birthplace-subcontainer {
      flex: unset;
      max-width: unset;
      margin: 0 15px;
    }
  }

  #it-ar-birthplace-col,
  #it-ar-birthplace-col2,
  #it-ar-birthplace-foreign {
    padding: 0;
  }

  .error-page-sign-up-button-container {
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 0.5px 0px 0px #d9d9d9 inset;
    padding: 16px;
    margin: -16px;

    @include medium-devices-tablet {
      margin-bottom: 80px;
      box-shadow: unset;
    }
  }

  #btn_indietro_container {
    display: none;
  }

  .sign-up-button {
    width: 100%;

    @include medium-devices-tablet {
      width: 200px;
      margin: 4px auto 0;

      .sign-up-button__icon-next {
        display: none;
      }
    }
  }

  #it-ar-birthplace-row {
    @include landscape-mobile {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .preFooter {
    @include medium-devices-tablet {
      order: 1;
    }
  }
}

.ocr-selection-container {
  .ocr-selection-card {
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    background-color: white;
    text-align: left;
    padding: 16px;
    display: grid;
    align-items: center;
    grid-template-rows: repeat(2, max-content);
    grid-template-columns: 1fr max-content;
    grid-auto-flow: column;
    column-gap: 1rem;

    &__title {
      font:
        800 16px/16px Muli,
        sans-serif;
      color: #222;
    }

    &__description {
      font:
        14px Roboto,
        sans-serif;
      color: #222;
    }

    &__nextIcon {
      width: 14px;
      height: 14px;
    }

    &__icon {
      grid-row: span 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border: 1px solid #bed62f;
      border-radius: 50%;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);

      &--primary {
        border: 1px solid rgba(34, 34, 34, 0.12);
        background-color: #bed62f;
      }
    }
  }
}

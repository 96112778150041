.summary-container {
  .summary-info-block {
    padding-bottom: 12px;
    display: grid;
    grid-template: max-content 1fr / 32px 1fr;
    row-gap: 4px;
    column-gap: 8px;

    &:not(:last-child) {
      border-bottom: 1px solid #d9d9d9;
    }
  }

  .summary-info-block-icon {
    grid-row: span 2;
    justify-self: center;
  }

  .summary-element-title {
    color: #262c2f;
    font:
      bold 14px "Roboto",
      sans-serif;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .summary-element-data {
    color: #262c2f;
    font:
      14px "Roboto",
      sans-serif;
    line-height: 18px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.errorCardContainer {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 48px 24px;
  box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  color: #222222;
  font-family: Roboto;
  background-color: white;
  z-index: 10;

  @include medium-devices-tablet {
    width: 500px;
  }

  .logo {
    align-self: center;
    margin-bottom: 32px;
  }

  .title {
    font-family: Muli;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;

    &.title-large-mb {
      margin-bottom: 32px;
    }
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 32px;

    &.text_dnc {
      font-size: 12px;
      line-height: 16px;
    }

    b {
      font-weight: 700;
    }

    a {
      color: #222222;
      text-decoration: underline;
    }
  }

  .button {
    padding: 9px 60px;
    border: none;
    border-radius: 7px;
    background-color: #bed62f;
    font-weight: 900;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    align-self: center;
    color: #222222 !important;

    &.outlineButton {
      background-color: unset;
      border: 1px solid #bed62f;
    }
  }

  .recovery_link {
    margin-top: 32px;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #222222;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 767px) {
  .button {
    width: 100%;
  }
}

.documentViewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: $primaryBgColor;
  overflow: hidden;
  .closePdf {
    background-color: $pdfHeaderBackgroundColor;
    position: absolute;
    top: 0;
    width: 100%;
    height: 3.5rem;
    z-index: 5;
    text-align: right;
    border-radius: 10px 10px 0px 0px;
    @include medium-devices-tablet {
      height: 5rem;
    }
    .pdf-header-title {
      position: absolute;
      font-family: Muli;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      color: $whiteColor;
      padding: 14px 16px;
    }
    .pdf-title-d-none {
      display: none;
    }
    .btn-discard {
      width: 1.5rem;
      height: 1.5rem;
      margin: 1rem;
      padding: 0;
      background: url("#{$images-path}/svg/icon/close-upfile-white.svg")
        no-repeat;
      background-size: 1.5rem 1.5rem;
      border: none;
      @include medium-devices-tablet {
        margin: 1.75rem;
        width: 1.5rem;
        height: 1.5rem;
        background-size: 1.5rem 1.5rem;
      }
    }
  }
  .react-pdf__Document {
    height: calc(100vh - 7rem);
    width: 100%;
    position: absolute;
    top: 3.5rem;
    overflow: auto;
    overflow-x: hidden;
    @include medium-devices-tablet {
      top: 5rem;
      height: calc(100vh - 10rem);
    }
    .react-pdf__Page__canvas {
      width: 100vw !important;
      height: calc(
        100vw * (842 / 595)
      ) !important; //per mantenere le proporzioni (595/842 = aspect ratio originale)
    }
  }
}

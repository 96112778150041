@import "./summaryInfoBlock/summaryInfoBlock";
@import "./summaryTextBlock/summaryTextBlock";
@import "./summaryRecaptcha/summaryRecaptcha";
@import "./summaryPopupInfoBlock/summaryPopupInfoBlock";
@import "./summaryPersonalData/summaryPersonalData";
@import "./summaryContacts/summaryContacts";
@import "./summaryAccessData/summaryAccessData";
@import "./summaryPopupFooter/summaryPopupFooter";
@import "./summaryAddress/summaryAddress";
@import "./summaryDocument/summaryDocument";
@import "./summaryRechargeLimits/summaryRechargeLimits";
@import "./summaryWelcomeBonus/summaryWelcomeBonus";

.summary-container {
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 518px;
  margin: 0 auto;
  box-sizing: content-box;

  .popup-modal {
    .popup-body {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .popup-modal-body {
      padding-bottom: 16px;

      @include medium-devices-tablet {
        padding-top: 16px;
      }
    }
  }

  .popup-label {
    color: #222;
    font:
      bold 14px Roboto,
      sans-serif;
    line-height: 18px;
  }

  .summary-sign-up-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 0.5px 0px 0px #d9d9d9 inset;
    background-color: white;

    @include medium-devices-tablet {
      position: unset;
      box-shadow: unset;
      padding: 16px 0;
    }

    .sign-up-button-container {
      gap: 16px;
    }

    .sign-up-button {
      flex: 1 0 auto;

      @include medium-devices-tablet {
        flex-grow: 0;
      }
    }
  }
}

#loyalty-green-banner {
  .loyalty-banner {
    position: fixed;
    display: flex;
    bottom: 0;
    background-color: #5d9d45;
    width: 100%;
    height: 78px;
    padding: 16px;
    border-radius: 7px 7px 0px 0px;

    @media screen and (min-width: 601px) {
      height: 52px;
    }

    .red-half-circle {
      position: absolute;
      top: -30px;
      left: 16px;
      background-color: #5d9d45;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px 8px 0px 0px;

      @media screen and (min-width: 601px) {
        left: 40px;
      }
    }

    .description-banner {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 218px;

      h6 {
        font-family: Roboto, sans-serif;
        color: white;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        line-height: 18px;
      }

      p {
        color: white;
        font-family: Roboto, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin: 0;
      }

      @media screen and (min-width: 601px) {
        flex-direction: row;
        width: fit-content;
        margin-left: 24px;

        h6 {
          white-space: nowrap;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
        }

        p {
          padding-left: 16px;
          white-space: nowrap;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .btn-banner {
      font-family: Roboto, sans-serif;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      padding: 8px 16px;
      max-height: 40px;
      border-radius: 8px;
      background-color: #ffffff;
      border: 1px solid #bed62f;
      text-align: center;
      line-height: 18px;
      font-size: 14px;
      font-weight: 700;
      color: #262c2f;

      @media screen and (min-width: 601px) {
        right: 40px;
      }
    }
  }
}

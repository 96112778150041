.calculate-fiscal-code-popup {
  .fiscal-code-gender {
    display: flex;
    gap: 1rem;
  }

  .male,
  .female {
    flex: 1 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2.7rem;
    border-radius: 5px;
    border: 1px solid #f0f2f4;
    background-color: white;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);

    color: #222;
    text-align: center;

    font: 700 normal 14px Roboto;
    line-height: 18px;

    &.selected {
      border-radius: 5px;
      border: 1px solid #bed62f;
      background: #f2f7d5;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
    }
  }
}

.loader {
  img {
    width: 3.75rem;
    height: 3.813rem;
    animation: spin 1200ms infinite linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

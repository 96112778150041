@import "./ocrSummaryTextBlock/ocrSummaryTextBlock";
@import "./ocrSummaryInfoBlock/ocrSummaryInfoBlock";
@import "./ocrSummaryPersonalData/ocrSummaryPersonalData";
@import "./ocrSummaryAddress/ocrSummaryAddress";
@import "./ocrSummaryDocument/ocrSummaryDocument";
@import "./wizardOcrSummaryDataFooter/wizardOcrSummaryDataFooter";
@import "./ocrSummarySection/ocrSummarySection";

.ocr-data-summary-container {
  padding: 0 16px 16px 16px;

  .ocr-data-summary-separator {
    border-top: 2px solid #d9d9d9;
    max-width: 500px;
    margin: 0 auto;
  }

  .ocr-summary-block-container {
    padding: 16px 0 24px;
    max-width: 500px;
    margin: 0 auto;
  }
}

.single-input-date {
  flex-grow: 1;

  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  [type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  input::-webkit-datetime-edit-day-field:focus,
  input::-webkit-datetime-edit-month-field:focus,
  input::-webkit-datetime-edit-year-field:focus {
    background-color: $selectInputDate;
    color: #222;
  }

  .form-group .form-control.is-present ~ .invalid-feedback {
    font-size: 12px !important;
  }

  .form-control:focus::placeholder {
    color: #929596;
  }

  .form-control:not(:focus)::placeholder {
    color: transparent;
  }

  .form-control.is-present {
    background-image: none;
  }
}

.otp-popup-main {
  display: flex;
  flex-direction: column;
  position: relative;

  .otp-popup-verified-overlay {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .otp-popup-verified {
      background: rgba(245, 251, 224, 0.8);
      backdrop-filter: blur(10px);
      border-radius: 10px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .otp-popup-verified-img {
        width: 45px;
        height: 45px;
        margin-bottom: 10px;

        img {
          width: 100%;
        }
      }

      .otp-popup-verified-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 120%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #0a7d41;
      }
    }
  }

  .otp-popup-resend-wait {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-left: 2px solid #ffaf1e;

    .otp-popup-resend-wait-text {
      margin-bottom: 0px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #222222;

      b {
        font-weight: 700;
      }

      & > span > span {
        display: block;

        @media screen and (min-width: 768px) {
          display: inline;
        }
      }
    }
  }

  .otp-popup-main-text {
    margin: 0px;
    margin-bottom: 32px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #222;

    b,
    .bold {
      font-weight: 700;
    }
  }

  .otp-popup-form-group {
    min-height: unset;

    &.timer-slot-notEmpty {
      margin-bottom: 8px;
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 8px;

      &.timer-slot-notEmpty {
        margin-bottom: 24px;
      }
    }

    .form-control {
      border: 1px solid #d1d2d8;

      &:focus {
        border-color: #bfd72f;
      }

      &.is-invalid {
        border-color: #e32219;

        & + .form-control-label {
          color: #e32219 !important;
        }
      }
    }

    .resended-otp,
    .invalid-new {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: left;
      margin-right: -15px;
      margin-top: 4px;
      width: 100%;
    }

    .is-invalid,
    .invalid-new {
      color: #e32219;
      background-image: none;
    }

    .invalid-new {
      display: block;
    }

    .resended-otp {
      color: #0a7d41;
    }
  }

  .otp-popup-main-resend {
    margin-top: 8px;
    margin-bottom: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #222222;

    .otp-popup-main-resend-text {
      margin-bottom: 0px;
    }

    .otp-popup-main-resend-link {
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
      color: #030303;
    }
  }
}

// .overlay.phone-pop {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 2;
//   width: 100%; /* Full width */
//   height: 100%; /* Full height */
//   overflow: auto; /* Enable scroll if needed */
//   // background-color: rgb(0,0,0); /* Fallback color */
//   background-color: rgba(0, 0, 0, 0.3); /* Black w/ opacity */
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   @media screen and (max-width: 768px) {
//     display: block;
//   }
// }

.phone-pop .popup {
  min-height: initial;
  // max-width: 552px !important;
  max-width: 570px !important;
  max-height: 410px !important;
  margin: auto;
  position: relative;
  width: 80% !important;
  height: 100% !important;
  padding-bottom: 32px;

  .bold {
    font-weight: bold;
  }
}

.phone-pop h5.clicca-su-accedi-ed b {
  font-weight: 900;
}

.phone-pop .popup .verifica-numero {
  max-width: 397px;

  font-family: Muli;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  letter-spacing: 0.0048em;
  color: #000000;
}

.phone-pop .popup .description {
  margin-top: 47px;
  margin-bottom: 43px;
  max-width: 356px;
}
.phone-pop .popup .accedi {
  display: block;
  justify-content: space-between;
  margin-bottom: 32px;

  &.l-message {
    margin-bottom: 40px;
  }
}

.phone-pop .popup .logo-sisal {
  width: 88px;
  height: 31px;
  margin-left: 97px;
  margin-top: 20px;
}

.phone-pop .popup .inserisci {
  max-width: 356px;
  margin-bottom: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
}

.phone-pop .popup .input-container {
  width: 68px;
  border-bottom: 2px solid #d9d9d9;
  border-radius: 0.5px;
  margin: 0 8px;
}

.phone-pop .popup .input {
  border: none;
  min-width: unset !important;
  box-sizing: border-box;
  width: 34px !important;
  max-width: 34px !important;
  position: relative;
  margin-bottom: 9px;
  text-align: center;
}

// .phone-pop .popup .main {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

.phone-pop .popup .nuovo {
  max-width: 482px;
  height: 36px;
  p {
    margin-bottom: 0px;
  }
}

.phone-pop .popup .nuovo .invia {
  text-decoration: underline;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;
  cursor: pointer;
  color: black;
}

.phone-pop .popup .hai-ricevuto-sms {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  text-align: center;
}

.phone-pop .popup .resended-otp {
  display: inline-flex;
  height: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  margin-right: -15px;
  margin-top: 0.5rem;
  width: 100%;
  color: #0a7d41;
}

.phone-pop .popup .feedbackerror {
  display: inline-flex;
  height: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  margin-right: -11px;
  margin-top: 0.5rem;
  width: 100%;
  color: #ff0000;
}

.phone-pop.popup.invalid-feedback,
.invalid-new {
  display: inline-flex;
  height: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  margin-right: -15px;
  margin-top: 0.5rem;
  width: 100%;
  color: #ff0000;
}

.phone-pop .popup .resend-wait-container {
  max-width: 336px;
  width: 100%;
  height: 36px;
  // margin-top: 40px;
}

.phone-pop .popup .resend-wait-container .resend-wait-text {
  // margin-top: 32px;
  margin-bottom: 0px;
  padding-left: 15px;
  border-left: 5px solid #ebb000;
  font-size: 12px;
  text-align: left;
}

.phone-pop .popup .continua {
  // position: absolute;
  // bottom: 32px;
  margin-top: 47px;
  background-color: #bed62f;
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 18px;
  border: none;
  padding: 0 0.75rem;
  height: 40px;
  width: 156px;
  border-radius: 7px;

  &.l-m {
    margin-top: 37px;
  }
}

.phone-pop span.close-btn {
  position: absolute;
  top: 5px;
  right: 18px;
  font-size: 35px;
  color: #333333;
  cursor: pointer;
}

.phone-pop .popup .head {
  justify-content: space-between;
  display: flex;
}

.phone-pop .popup .is-invalid {
  .input-container {
    border-bottom: 2px solid #ff0000;
  }
}

.phone-pop {
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    .popup {
      background-color: #ffffff;
      width: 100% !important;
      height: 100% !important;
    }
    .logo-sisal {
      display: none;
    }

    .description {
      max-width: 328px;
      margin-top: 1.5rem !important;
    }

    .verifica-numero {
      width: 287px;
      height: 56px;
      left: 16px;
      margin-top: 30px;
    }

    .inserisci {
      height: 32px;
      left: 4.44%;
      right: 4.44%;
      top: calc(50% - 32px / 2 - 149px);
      width: 100%;
    }

    .accedi {
      // margin-top: 40px;
    }

    .input-container {
      width: 40px !important;
    }

    .input {
      width: 20px !important;
    }

    .nuovo {
      // margin-top: 40px;
      max-width: 482px;
    }

    .nuovo .invia {
      // margin-top: 7px;
      margin-left: 0 !important;
    }

    .hai-ricevuto-sms {
      height: 16px;
      left: 18.89%;
      right: 45%;
      top: calc(50% - 16px / 2 - 8px);

      // display: flex;
      // flex-direction: column;
      // justify-content: flex-start;
      // align-items: flex-start;
      width: 100%;
      max-width: 282px;
    }
    .continua {
      position: static;
      bottom: 0;
      width: 156px !important;
      height: 40px;
      left: 102px;
      top: 388px;
      background: #def199;
      border-radius: 4px !important;
      margin-top: 2rem !important;
    }

    .head {
      justify-content: space-between;
      display: flex;
    }
  }
}

@media screen and (max-width: 768px) {
  .phone-pop .popup {
    max-width: 100% !important;
    width: 100% !important;
    margin: 0;
    border-radius: 0 !important;
    min-height: 100vh !important;
    max-height: initial !important;
    height: initial !important;
  }

  .phone-pop .main {
    width: 100%;
  }
  .description {
    max-width: 80%;
  }
  .phone-pop .popup .hai-ricevuto-sms {
    width: 100%;
  }
  .phone-pop .popup .nuovo .invia {
    margin-top: 7px;
    margin-left: 0 !important;
  }

  .phone-pop .popup .resend-wait-container {
    width: auto;
  }
}

.wizard {
  padding-bottom: 40px;
  .mt-5 {
    margin-top: 15px !important;
  }
}

#it-ar-personalData-container {
  .generate-fc-step {
    max-width: 100%;
    flex: 0 0 100%;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.personDat-D {
  display: flex;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }

  .form-group {
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &:first-child {
      margin-right: 12px;
      @media screen and (max-width: 768px) {
        margin: 0;
      }
    }
    &:last-child {
      @media screen and (max-width: 768px) {
        margin: 0;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .bg_cover {
    background-size: contain;
    height: 296px;
    background-position: center;
    max-width: 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -34px;
    transform: scale(1.25);
    overflow: hidden;
    /* z-index: 4; */
    //filter: opacity(0.6);
    background-image: url("#{$images-path}/svg/Illustration_D_D.svg");
  }

  .container.ImgPersonalData {
    padding-top: 0 !important;
    margin-top: -15px;
  }
}

@media screen and (max-width: 600px) {
  .wizard {
    overflow: hidden;
    position: relative;
  }
  .cover_all {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .body_cover {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .bg_cover {
    // background-image: url(/static/media/backMobile.ebe6783e.svg);
    background-image: url("#{$images-path}/svg/backMobile.svg");
    background-size: contain;
    background-size: 100% 100%;
    height: 303px;
    background-position: center;
    max-width: 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -54px;
    overflow: hidden;
    width: 100%;
  }
  .container.ImgPersonalData {
    padding-top: 0 !important;
    margin-top: -50px;
  }

  .body_cover:before {
    content: "";
    // background-image: url(/static/media/circle.dfdc8162.svg);
    background-image: url("#{$images-path}/svg/circle.svg");
    background-size: 100% 100%;
    background-position: center;
    max-width: 100%;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .body_cover div#it-ar-contacts-setActive {
    background-color: #fff;
    height: 2.75rem;
  }

  .cover_all.Eflusso {
    overflow: visible;
    .body_cover:before {
      top: 30px;
    }

    .contacts .emailPrediction .gradient {
      background-image: none;
    }
  }
}

@media screen and (max-width: 474px) {
  .body_cover:before {
    background-size: contain;
  }
  .cover_all.Eflusso {
    .body_cover:before {
      background-size: 100% 100%;
      top: 50px;
    }
  }
}

@media screen and (max-width: 380px) {
  .body_cover:before {
    top: -40px;
  }
}

@media screen and (max-width: 340px) {
  .bg_cover {
    top: -60px;
  }
  .container.ImgPersonalData {
    margin-top: -70px;
  }
}

.submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
  height: 36px;
  padding: 0px 16px;
  border: 1px solid rgba(34, 34, 34, 0.12);
  background: #bed62f;
  border-radius: 8px;
  font:
    700 14px/1 "Roboto",
    sans-serif;
  color: #222;

  &:disabled {
    background: #f2f7d5;
    color: #909090;

    .submit-btn__icon-next {
      filter: invert(62%) sepia(4%) saturate(15%) hue-rotate(332deg)
        brightness(91%) contrast(91%);
    }
  }

  &__icon-next {
    width: 12px;

    @include medium-devices-tablet {
      display: unset;
    }
  }
}

@import "../../../sass/variables";

.ocr-data-summary-container {
  .ocr-summary-text-block {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 500px;
    margin: 0 auto;
  }

  .title-form {
    color: $textSelectColor;
    margin: 0;
    font:
      800 20px/1 "Muli",
      sans-serif;
  }

  .label-form {
    color: $textSelectColor;
    font:
      14px/1 "Roboto",
      sans-serif;
    margin: 0;
  }
}

@import "./documentSelectionForm/documentSelectionForm";
@import "./documentSelectionCta/documentSelectionCta";
@import "./documentSelectionNav/documentSelectionNav";
@import "./documentSelectionHeadForm/documentSelectionHeadForm";

.document-selection-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
}

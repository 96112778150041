.loadingGif {
  .all-black-gif {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $whiteColor;
    left: 0;
    top: 0;
    z-index: 10;
    overflow: hidden;
    .loading-block {
      width: 24.06rem;
      height: 24.06rem;
      top: 60%;
      left: 50%;
      position: relative;
      @include sisal-transform(translate(-50%, -29%) rotate(-45deg));

      .loading-gif {
        width: 100%;
        height: 100%;
        @include sisal-animation-iteration-count(infinite);
        @include sisal-animation-timing-function(linear);
        @include sisal-animation-name(animated-loading);
        position: absolute;
        visibility: hidden;
        .gif-frame {
          width: 5rem;
          height: 5rem;
          @include sisal-transform(rotate(-45deg));
        }
      }
      @for $i from 1 through 7 {
        .loading-gif:nth-child(#{$i}) {
          animation: animated-loading 21s infinite;
          @include sisal-animation-delay((#{($i - 1) * 3}s));
        }
      }
    }
    .loading-text {
      color: $loadingGreyBg;
      text-align: center;
      width: 100%;
      max-width: 20rem;
      position: absolute;
      top: 60%;
      left: 50%;
      @include sisal-transform(translate(-50%, -100%));
    }
    .secondTextDiv {
      height: 120px;
      width: 280px;
      border-radius: 10px;
      background-color: #e8e8eb;
      display: flex;
      justify-content: center;
      @include medium-devices-tablet {
        height: 134px;
        width: 424px;
      }
    }

    .secondTextDiv p {
      color: #333333;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      margin: 15px;
      @include medium-devices-tablet {
        font-size: 16px;
        line-height: 25px;
        margin: 20px;
      }
    }
    .secondTextDiv a {
      cursor: pointer;
    }
    .secondLoading-text {
      color: $loadingGreyBg;
      text-align: center;
      width: 100%;
      max-width: 20rem;
    }
    .positionImg {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .staticImgBettin {
      width: 5rem;
      height: 5rem;
      margin-top: -80px;
    }
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    .main {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4.7rem;

      .document {
        margin: 5.8rem 35rem 0 35rem;
      }

      .description {
        margin-top: 0.8rem;
        display: contents;
      }

      .ops-si-sono-verifi {
        color: rgba(0, 0, 0, 0.8);
        font-family: Muli;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        max-width: 340px;
      }

      .non-preoccuparti-pu {
        height: 60px;
        width: 446px;
        color: #000000;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        margin: 1rem 1.02rem 1.5rem 1.02rem;
        max-width: 19rem;
      }

      .component-button-primary-activ {
        background-color: #bed62f;
        color: #222222;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.28px;
        line-height: 18px;
        margin-top: 1rem;
        border: none;
        padding: 11px 10px 11px 27.23px;
        border-radius: 7px;
      }

      .icon-arrow-down-icon-arrow-for {
        height: 16px;
        width: 16px;
      }
    }
  }

  .main {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .document {
      margin: 5.8rem 35rem 0 35rem;
    }

    .description {
      margin-top: 1.5rem;
    }

    .ops-si-sono-verifi {
      color: rgba(0, 0, 0, 0.8);
      font-family: Muli;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }

    .non-preoccuparti-pu {
      font-family: Roboto;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin-bottom: 0;
    }

    .component-button-primary-activ {
      display: flex;
      align-items: center;
      background-color: #bed62f;
      color: #222222;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.28px;
      line-height: 18px;
      margin-top: 1rem;
      border: none;
      padding: 11px 10px 11px 27.23px;
      border-radius: 7px;
      margin-top: 1.5rem;
    }

    .icon-arrow-down-icon-arrow-for {
      height: 16px;
      width: 16px;
      margin-left: 0.5rem;
    }

    .last-line {
      color: #000000;
      font-family: Roboto;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
  }
}

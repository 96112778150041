.activeAccount {
  .styleBack {
    span {
      line-height: initial;
      width: 150px;
      color: #222222;
      font-family: Roboto;
      font-size: 12px;
      font-weight: bold;

      @media screen and (min-width: 576px) {
        width: fit-content;
      }
    }
  }
}

.document-selection-container {
  .document-selection-cta {
    background-color: white;
    color: #222;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border: 1px solid #f2f2f2;
    padding: 16px;
    cursor: pointer;

    font:
      bold 14px / 18px "Roboto",
      sans-serif;

    input {
      appearance: none;
      display: none;
    }

    &--selected {
      background-color: #f2f7d5;
      border-color: #bed62e;
    }
  }
}

.custom-file-input {
  text-align: left;
  position: absolute;
}

.input-group {
  height: auto;
  border: 0.125rem solid black;
  @include medium-devices-tablet {
    height: 3.75rem;
  }
}

.form-group {
  .input-group-upload {
    border-radius: 0.31rem;
    max-width: 42.5rem;
    background: $hoverInput;
    border: 2px dashed #b3b3b3;
    height: 3.875rem;
    margin-top: 0.25rem;
    .custom-file {
      height: 100%;
      border-radius: 0.31rem;
      margin: 0;
      display: flex;
      align-items: center;

      input {
        height: 100%;
      }

      .icon {
        display: flex;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        &.upload-icon {
          width: 1.875rem;
          height: 1.875rem;
          max-width: 1.875rem;
          left: 1.56rem;
        }

        &.document-icon {
          max-width: 2.81rem;
          max-height: 3.125rem;
          width: auto;
          height: auto;
          left: 1.375rem;
        }

        img {
          margin: auto;
          max-width: inherit;
          max-height: inherit;
        }
      }

      .upload-text-container {
        width: 100%;
        padding-right: 1.875rem;
        padding-left: 5rem;
        .upload-text {
          color: #222222;
          font-family: Muli;
          font-size: 16px;
          font-weight: 900;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          margin-bottom: 0;

          &.upload-text-title {
            margin-top: 0.31rem;
            margin-bottom: -0.5rem;
            &.uploadAnother {
              color: #999999;
            }
          }

          &.upload-text-subtitle {
            color: #222222;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            &.uploadFile {
              color: #999999;
            }
          }
        }
      }

      .discard-icon,
      .error-icon {
        z-index: 6;
        max-width: 5.375rem;
        position: absolute;
        height: 1.25rem;
        width: 1.25rem;
        right: 0.8rem;
        top: 50%;
        transform: translate(0, -50%);
        @include medium-devices-tablet {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
      .discard-icon {
        cursor: pointer;
        background: url("#{$images-path}/svg/icon/close-upfile.svg") no-repeat;
      }
      .error-icon {
        display: block !important;
      }
    }
  }

  &.form-group-upload-full {
    max-width: 42.5rem;
    // background: $hoverInput;
    .input-group-upload {
      border: none;
    }
    .custom-file {
      .upload-text-container {
        padding-right: 4.375rem;

        .upload-text {
          text-overflow: ellipsis;

          &.upload-text-title {
            color: $primaryTextColor;
          }
        }
      }
    }
  }
}

.summary-input {
  .form-group {
    .input-group-upload {
      color: #3a3a3a;
      padding: 0.625rem 0.625rem 0.625rem 0.125rem;
      display: block;
      width: 100%;
      border: 0.125rem solid #e8e8eb;
      border-radius: 0.25rem;
      font-size: 1rem;
      height: 2.75rem;
      font-weight: 500;
      background: white;
      .custom-file {
        .icon {
          &.upload-icon {
            left: 0.6875rem;
            margin-top: 3px;
          }
          img {
            width: 33px;
            height: 24px;
          }
          &.document-icon {
            left: 0.6875rem;
          }
        }

        .upload-text-container {
          padding-right: 0.6875rem;
          padding-left: 3.563rem;
          margin-top: 6px;

          .upload-text {
            &.upload-text-title {
              margin: 0;
              font-weight: 300;
              color: #a7a9b4;
              font-size: 14px;
            }

            &.upload-text-subtitle {
              display: none;
            }
          }
        }

        .discard-icon,
        .error-icon {
          right: 0.1rem;
        }
      }
    }

    &.form-group-upload-full {
      background: none;

      .input-group-upload {
        .custom-file {
          .upload-text-container {
            padding-right: 2.5rem;
            padding-left: 4.188rem;

            .upload-text {
              &.upload-text-title {
                color: #3a3a3a;
                font-size: 16px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}
.col-3 input[type="file"] {
  visibility: hidden;
  position: absolute;
}
.qcentopx {
  width: 25rem;
}

.ocr-data-summary-container {
  .ocr-summary-personal-data {
    max-width: 500px;
    margin: 0 auto;
    padding-bottom: 24px;

    .fiscalCode {
      padding: 0;
    }

    .cf-with-calc-block {
      height: unset;
    }
  }
}

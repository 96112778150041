.container.sex {
  .row {
    &.is-invalid {
      .form-control-label.label-date {
        color: $primaryErrorColor;
        font-weight: 500;
      }
      .input-group .form-control {
        border-bottom: 0.125rem solid $primaryErrorColor;
      }
    }
    .check-sex {
      .select-customer {
        max-width: 6.25rem;
      }
    }
  }
}

div#it-ar-securityquestion-col2 .form-group {
  min-height: 3rem;
}

div#it-ar-securityquestion-col2 {
  margin-bottom: 30px;
}

.flussoWizard {
  .accordion {
    div#it-ar-securityquestion-col2 {
      margin-bottom: 18px;
    }
  }
}

p#it-ar-sex-ourSecret {
  display: none;
}

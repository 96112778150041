@import "gradient";

@mixin sisal-linear-double-gradient($dir, $first, $fperc, $second, $sperc) {
  @if dir-or-angle($dir) == false {
    $dir: 180deg;
  }
  background: -moz-linear-gradient(
    legacy-dir(legacy-dir($dir)),
    $first $fperc,
    $second $sperc
  ); /* ff3.6+ */
  background: -webkit-linear-gradient(
    legacy-dir(legacy-dir($dir)),
    $first $fperc,
    $second $sperc
  ); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    legacy-dir(legacy-dir($dir)),
    $first $fperc,
    $second $sperc
  ); /* opera 11.10+ */
  background: -ms-linear-gradient(
    legacy-dir(legacy-dir($dir)),
    $first $fperc,
    $second $sperc
  ); /* ie10+ */
  background: linear-gradient($dir, $first $fperc, $second $sperc);
}
@mixin sisal-linear-double-gradient-image(
  $dir,
  $first,
  $fperc,
  $second,
  $sperc
) {
  @if dir-or-angle($dir) == false {
    $dir: 180deg;
  }
  background-image: -moz-linear-gradient(
    legacy-dir(legacy-dir($dir)),
    $first $fperc,
    $second $sperc
  ); /* ff3.6+ */
  background-image: -webkit-linear-gradient(
    legacy-dir(legacy-dir($dir)),
    $first $fperc,
    $second $sperc
  ); /* safari5.1+,chrome10+ */
  background-image: -o-linear-gradient(
    legacy-dir(legacy-dir($dir)),
    $first $fperc,
    $second $sperc
  ); /* opera 11.10+ */
  background-image: -ms-linear-gradient(
    legacy-dir(legacy-dir($dir)),
    $first $fperc,
    $second $sperc
  ); /* ie10+ */
  background-image: linear-gradient($dir, $first $fperc, $second $sperc);
}
@mixin sisal-box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin sisal-background-origin($origin...) {
  -moz-background-origin: str-replace($origin, "-box", "");
  -webkit-background-origin: $origin;
  background-origin: $origin;
}
@mixin sisal-background-size($size...) {
  -webkit-background-size: $size;
  -moz-background-size: $size;
  -o-background-size: $size;
  background-size: $size;
}
@mixin sisal-background-clip($clip...) {
  -webkit-background-clip: $clip;
  background-clip: $clip;
}
@mixin sisal-transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin sisal-transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}
@mixin sisal-transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}
@mixin sisal-transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}
@mixin sisal-transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin sisal-box-sizing($sizing) {
  -webkit-box-sizing: $sizing;
  -moz-box-sizing: $sizing;
  box-sizing: $sizing;
}

@mixin sisal-animation-iteration-count($count) {
  -webkit-animation-iteration-count: $count;
  -moz-animation-iteration-count: $count;
  -ms-animation-iteration-count: $count;
  -o-animation-iteration-count: $count;
  animation-iteration-count: $count;
}

@mixin sisal-animation-timing-function($timing) {
  -webkit-animation-timing-function: $timing;
  -moz-animation-timing-function: $timing;
  -ms-animation-timing-function: $timing;
  -o-animation-timing-function: $timing;
  animation-timing-function: $timing;
}
@mixin sisal-animation-name($name) {
  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -ms-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;
}

@mixin sisal-animation-delay($delay...) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -ms-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
}
@mixin sisal-animation-duration($duration...) {
  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -ms-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;
}

@mixin sisal-transform($transformation...) {
  -webkit-transform: $transformation;
  -ms-transform: $transformation;
  transform: $transformation;
}

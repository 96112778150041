.summary-container {
  .popup-modal {
    .secondary-button {
      border: 1px solid #bed62f;
      background: #fff;
    }

    .popup-modal-footer {
      button {
        padding: 9px;

        @include medium-devices-tablet {
          width: 150px;
        }
      }
    }
  }
}

.securityQuestion {
  .errorAnswer {
    color: #ff3c45;
    font-weight: 500;
    padding-left: 0.31rem;
    font-size: 12px;
  }
  .select-customer {
    color: #3a3a3a;
    padding: 0.625rem 0.625rem 0.625rem 0.8125rem;
    display: block;
    width: 100%;
    border: 1px solid $borderInput;
    border-radius: 0.25rem;
    font-size: 1rem;
    height: 2.75rem;
    font-weight: 500;
  }
  .accordion-sq-label {
    top: -1rem;
    color: $textColor;
    font-size: 0.75rem;
    @include medium-devices-tablet {
      font-size: 1rem;
    }
  }
  .opened-list {
    // position: fixed;
    // transform: translate(0, -50%);
    // z-index: 3;
    // max-width: calc(100% - 30px);
    // top: 50%;
    position: absolute;
    z-index: 3;
    max-width: 100%;
    top: 60%;

    @include sisal-box-shadow(0 0.125rem 1.25rem 0 $boxShadowGrey);
    // @include landscape-mobile {
    //   top: 0;
    //   position: absolute;
    //   transform: translate(0, 0);
    //   max-width: 680px;
    // }
    li {
      @include ie11support {
        height: 3.5rem;
      }
    }
  }
  .otherwise {
    line-height: 50px;
    padding: 0 24px;
    align-items: center;
  }
  .hr-left {
    margin-right: 17px;
  }
  .hr-right {
    margin-left: 17px;
  }
}

.preFooter {
  &.preFooter-mobile-text-container {
    @include medium-devices-tablet {
      display: none;
    }

    width: 100%;
    margin-bottom: 60px;
    font: 12px Roboto;
    text-align: center;
  }

  .contactUs {
    border: none;
    background-color: unset;
    color: #222;
    padding: unset;
    text-decoration: underline;
    font-weight: bold;
  }
}

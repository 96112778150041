.typ-spid {
  min-height: 100vh;
  line-height: 18px;
  color: #222;
  padding-bottom: 140px;

  @media screen and (min-width: 601px) {
    padding-bottom: 40px;
  }

  @include desktop {
    padding-bottom: 0;
  }

  b {
    font-weight: 700;
  }

  .top-left-bg {
    position: absolute;
    top: 0;
    left: 0;
  }

  .bottom-right-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media screen and (max-width: 640px) {
    .top-left-bg,
    .bottom-right-bg {
      display: none;
    }
  }

  .sisal-header {
    .container-fluid {
      display: flex;
      justify-content: space-between;
    }
  }

  .typ-spid-container {
    max-width: 500px;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 120px;
    isolation: isolate;
    font-size: 14px;

    @include landscape-mob {
      padding: 16px;
    }

    .typ-spid-headline {
      margin-bottom: 16px;

      .typ-spid-title {
        margin-bottom: 8px;
        font-family: Muli;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }
    }

    .typ-spid-progress {
      padding: 0;

      .typ-spid-progress-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .typ-spid-progressbar {
          position: relative;
          margin: 4px;
          float: left;
          text-align: center;

          span {
            position: relative;
            top: -10px;
            font-size: 24px;
          }

          .typ-spid-barOverflow {
            position: relative;
            overflow: hidden;
            width: 150px;
            height: 70px;
            margin-bottom: -14px;

            .typ-spid-bar {
              position: absolute;
              top: 0;
              left: 0;
              width: 150px;
              height: 150px;
              border-radius: 50%;
              box-sizing: border-box;
              border: 8px solid #f0f2f4;
              border-bottom-color: #0a7d41;
              border-right-color: #0a7d41;
              // transform: rotate(153deg); // 45 + ( position * 1.8)
            }
          }

          .typ-spid-left {
            position: absolute;
            // background: #0a7d41; // for 0% -> #f0f2f4;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            left: 0;
            bottom: 4px;
            overflow: hidden;
          }

          .typ-spid-right {
            position: absolute;
            // background: #f0f2f4; // for 100% -> #0a7d41;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            right: 0;
            bottom: 4px;
            overflow: hidden;

            .typ-spid-back {
              width: 8px;
              height: 8px;
              // background: #f0f2f4; // for 100% -> #0a7d41;
              position: absolute;
            }
          }
        }

        .typ-spid-progress-bar-text {
          width: 300px;
          text-align: center;
          color: #222222;
        }
      }

      .typ-spid-progress-status {
        padding: 8px 0;

        .typ-spid-progress-status-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          padding: 10px 12px;
          margin-bottom: 8px;
          border: 1px solid #f0f2f4;
          border-radius: 10px;
          background-color: #fff;

          &:last-child {
            margin-bottom: 0;
          }

          .typ-spid-progress-status-info {
            display: flex;
            align-items: center;

            .typ-spid-progress-status-info-box {
              display: flex;
              flex-direction: column;
              margin-left: 12px;

              .typ-spid-progress-status-info-name {
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
              }

              .typ-spid-progress-status-info-todo {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #222222;
              }
            }
          }

          .typ-spid-progress-status-actions {
            .typ-spid-progress-status-verified {
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 130%;
              color: #40cd8c;
            }

            .typ-spid-progress-status-button {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #bed62f;
              border-radius: 7px;
              height: 30px;
              width: 74px;
              font-weight: 500;
              font-size: 12px;
              text-align: center;
              background-color: #fff;
              color: #262c2f !important;
            }
          }
        }
      }
    }

    .typ-spid-note {
      margin: 0 0 16px 0;
      padding: 0 8px;
      border-left: 2px solid #ffaf1e;
      font-size: 12px;
      line-height: 14px;

      @include medium-devices-tablet {
        margin: 8px 0 16px 0;
      }
    }
  }

  .sisal-thank-you-banner {
    isolation: isolate;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    color: #222222;
    background-color: #fef1cc;
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;
    min-height: 60px;
    border-bottom: 2px solid #fabb00;
    padding-left: 24px;
    padding-right: 24px;

    @media screen and (max-width: 768px) {
      align-items: unset;
      padding-left: 16px;
      flex-direction: column;
      min-height: 87px;
      padding-bottom: 16px;
    }
  }

  .sisal-thank-you-banner-right-gift {
    position: absolute;
    right: 10px;
    bottom: 0;
    @media screen and (max-width: 768px) {
      right: 0px;
      bottom: 0px;
    }
  }

  .sisal-thank-you-banner-gift-img {
    width: 100%;
    height: 100%;
  }

  .sisal-thank-you-banner-tag {
    background-color: #ffbf00;
    color: inherit;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    margin-right: 16px;
    white-space: nowrap;
    @media screen and (max-width: 768px) {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      margin-top: 0;
      width: fit-content;
      margin-bottom: 5px;
    }
  }

  .sisal-thank-you-banner-title {
    font-weight: bold;
    margin: 0;
    text-align: center;
    margin-right: 16px;
    width: fit-content;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      margin-right: 0px;
    }
  }

  .sisal-thank-you-banner-description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    text-align: center;
    width: 100%;

    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: left;
      align-items: center;
      margin-top: 0;
      width: calc(100vw - 140px);
      gap: 4px;
    }
  }

  .sisal-thank-you-banner-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-right: 16px;
    vertical-align: middle;

    @media screen and (max-width: 768px) {
      margin-right: 0px;
      margin-bottom: 4px;
      line-height: 18px;
      text-align: left;
    }
  }

  .sisal-thank-you-banner-link {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    color: inherit;
    line-height: 21px;
    padding: 0;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #bed62f;
    padding: 8px 16px 8px 16px;
    margin-right: 30px;

    &:hover {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      position: absolute;
      right: -12px;
      top: 60%;
      transform: translateY(-50%);
      margin-bottom: 4px;
    }
  }

  @import "./typContactCheckModal/typContactCheckModal";
}

.ocr-data-summary-container {
  .input-field-form {
    max-width: 500px;
    margin: 0 auto;

    @include desktop {
      max-width: unset;
    }
  }
}

.ocr-data-summary-container {
  .ocr-summary-address {
    .container {
      padding-left: unset;
      padding-right: unset;
    }

    .input-field-form {
      max-width: 500px;

      .row {
        margin-left: unset;
        margin-right: unset;

        & > div {
          flex: 1;
        }
      }
    }
  }
}

$whiteColor: #fff;
$blackColor: #000;

$orangeColor: #f7941e;
$primaryGreenColor: #00653b;
$secondaryGreenColor: #3b914c;

// **** STYLE COLOR BODY **** //
$primaryBgColor: #fff;

// **** STYLE COLOR SELECT **** //
$textSelectColor: #222222;

// **** STYLE COLOR TEXT **** //
$textColor: #929596;
$formTitle: #bed62f;
$formLabel: #b9bbc3;
$formSubTitle: #474a61;
$formHeaderSubTitle: #000000;
$textAlreadyPresent: #e32219;
$textPlaceholderAfter: #262c2f;
$textcheckList: #409142;
$textrecharge: #a5a7b2;
$termSubstring: #363952;
$pdvColorText: #191c3c;
$inactivePromoBarTitle: #747788;
$activePromoBarTitle: rgba(25, 29, 58, 0.8);
$inlineLabel: rgba(25, 29, 58, 0.6);

// **** STYLE COLOR BTN **** //
$gradientsGreenDark: #8cc73f;
$gradientsGreenLight: #bfd72f;
$shadowBnt: rgba(121, 172, 54, 0.7);
$boxShadowGrey: rgba(200, 200, 200, 0.7);
$suggestBtn: rgba(25, 29, 58, 0.6);

// **** STYLE COLOR CARD ATTIVAZIONE **** //
$borderYellow: #ffdd02;

// **** STYLE COLOR ERROR **** //
$primaryErrorColor: #e32219;

// **** STYLE COLOR EMAIL **** //
$borderEmailBtn: #979797;

// **** STYLE COLOR INPUT **** //
$primaryBorderColor: #a7a7a7;
$primaryTextColor: #191d3a;
$greyTextColor: #707070;
$borderInput: #d4d5d5;
$hoverInput: #f3f3f5;
$selectNation: rgba(25, 29, 58, 0.05);
$uploadText: #747788;
$invalidInput: #fff5f5;
$greyBorderColor: #191d3a;
$orangeTitleForm: #f7941e;
$shadowPrimary: rgba(121, 172, 54, 0.7);
$shadowPromoBar: rgba(0, 0, 0, 0.25);
$inputTextError: #ff0000;
$inputTextValid: #02643d;
$uncheckedInputBg: #f3f5f8;
$errorInputBg: rgba(255, 0, 0, 0.1);
$validInputBg: #f3f5f8;
$handler: rgba(25, 29, 58, 0.1);
$invalidInputBorder: #ff989d;

/* **** STYLE COLOR MESSAGE INFO **** */
$messageInfoGrey: #5e6075;
$messageInfoRed: #fde8e9;
$messageInfoYellow: #fff8cc;

/* **** STYLE COLOR ATTENTION MESSAGE **** */
$attentionGrey: #e8e8eb;

/* **** STYLE COLOR BONUS GRADIENT **** */
$bonusLightBlue: #61c2d0;
$bonusDarkBlue: #0587cd;
$bonusShadow: rgba(0, 0, 0, 0.71);
$bonusGreen: #96bc25;
$bonusLightGreen: #bfdc65;
$bonusLightRed: #d45b78;
$bonusRed: #be3030;
$bonusYellow: #fae06d;
$bonusDarkYellow: #f5ac68;
$bonusFloatingLightBlue: #19b2ff;

/* **** STYLE COLOR LOADING GIF BACKGROUND **** */
$loadingGreyBg: #2e282e;
$selectInputDate: #daecc3;

/* **** STYLE COLOR BACKGROUND OVER LAYER **** */
$layerBg: rgba(255, 255, 255, 0.5);
$layerGreyBg: rgba(0, 0, 0, 0.5);

/* **** STYLE COLOR LOADER **** */
$loaderBg: rgba(255, 255, 255, 0.95);

/* **** STYLE DISABLED BTN **** */
$disabledLight: #cccccc;
$disabledDark: #acafc2;

/* **** STYLE PDF BACKGROUND COLOR **** */
$pdfHeaderBackgroundColor: #2f3c43;

/* **** STYLE COLOR STEPS BAR **** */
$stepBarText: #262c2f;
$stepBorder: #dcdcdc;
$stepBorderActive: #0b7d3e;
$stepFillColor: #cee5d8;

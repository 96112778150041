.buttonLinkPdv {
  display: flex;
  padding: 16px;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #f0f2f4;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
  margin-top: 24px;
  cursor: pointer;
  justify-content: center;
  background-color: white;

  .linkIconContainer {
    min-width: 43px;
    height: 43px;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .linkIcon {
      width: 24px;
      height: 22px;
    }
  }

  .textLinkPdv {
    font:
      400 12px / 14px Roboto,
      sans-serif;
    text-align: left;
    color: #222222;
    padding-left: 10px;
    margin-bottom: 0px;

    span {
      display: block;

      &.pdvTitle {
        font:
          700 14px/18px Muli,
          sans-serif;
        margin-bottom: 8px;
      }
    }
  }
}

.summary-container {
  .re-captcha {
    color: #2f3c43;
    font: 12px "Roboto";

    a {
      color: #b9d137;
      font-weight: 500;
      text-decoration: none;
    }
  }
}

.welcomeBonus {
  .maskNew {
    opacity: 1;
    height: 100%;
    width: 100%;
    color: $whiteColor;
    border-radius: 0.75rem;
    @include sisal-transition(opacity 0.3s);
    background: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_New.png")
      no-repeat center;
    background-size: cover;
    min-height: 12rem;
    position: relative;

    @include medium-devices-tablet {
      padding: 0;
      min-height: unset;
      max-width: unset;
      min-width: unset;
    }

    .cont-description {
      display: block;
      -webkit-line-clamp: 3;
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 3.5rem;
      width: 65%;
      font-size: 14px;
      line-height: 18px;
      max-width: 300px;
      &.desk-without-link {
        -webkit-line-clamp: 3;
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
      @include medium-devices-tablet {
        width: 70%;
        display: -webkit-box;
        height: 4rem;
        line-height: 1rem;
        -webkit-line-clamp: 5;
      }
    }

    .element-button-secondary-activ {
      display: none;
    }

    &.active {
      box-shadow: none;

      .cont-description {
        display: -webkit-box;
      }

      .element-button-secondary-activ {
        display: block;
      }
    }

    > div {
      padding: 20px 8px 8px 47px;
      border-radius: 0.75rem;
      height: auto;
      overflow: hidden;

      a {
        color: $whiteColor;
        text-decoration: underline;
        font-size: 12px;
        font-weight: bold;
      }
      h3 {
        height: auto;
        margin-bottom: 0.5rem;
        font-family: Muli;
        font-size: 20px;
        font-weight: 800;
      }
      p {
        margin-bottom: 0.31rem;
        text-overflow: ellipsis;
        font-size: 0.75rem;
        @include medium-devices-tablet {
          font-size: 1rem;
        }
      }

      h5 {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .check-mask {
        width: 24px;
        height: 24px;
        display: block;
        position: absolute;
        top: 20px;
        left: 15px;
        cursor: pointer;

        img {
          width: 100%;
          height: 1.5rem;
          vertical-align: top;
        }
      }
    }
  }
}

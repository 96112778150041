.banner-loyalty-lottery {
  display: flex;
  margin: 0 auto;
  padding: 16px;
  border: 1px solid #f0f2f4;
  border-radius: 10px;
  box-shadow: 0px 2px 22px 0px #00000014;
  cursor: pointer;

  @media screen and (min-width: 601px) {
    padding: 8px;
  }

  .banner-text-bonus {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    justify-content: center;
    margin: 0;
    padding-left: 16px;

    .title {
      color: #262c2f;
      font:
        700 12px/14px Roboto,
        sans-serif;
      margin: 0;
    }

    .question-title {
      color: #262c2f;
      font:
        700 14px/18px Roboto,
        sans-serif;
      margin: 0;
    }

    .body {
      color: #262c2f;
      margin-bottom: 0px;
      font:
        12px/14px Roboto,
        sans-serif;
    }

    @media screen and (min-width: 601px) {
      padding-left: 8px;
    }
  }

  .banner-btn-bonus {
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: flex;
      margin: auto;
    }
  }

  &.no-shadow {
    box-shadow: none;
    cursor: auto;
  }
}

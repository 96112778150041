.preFooter {
  .popup-col {
    display: flex;
    align-items: center;
    height: 40px;
  }

  .icon-circle {
    margin-right: 8px;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #bed62f;
    display: grid;

    img {
      margin: auto;
    }
  }

  .preFooter-caption-title {
    font: 500 12px Roboto;
    line-height: 12px;
  }

  .preFooter-caption-action {
    font: 700 16px Roboto;
    line-height: 20px;
    color: black;
    text-decoration: underline;
    background-color: white;
    border: 0;
    padding: 0;
  }
}

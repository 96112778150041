.identification {
  .header-form {
    margin-bottom: 30px;
  }
  .alert-box-document {
    margin: 0 0 16px;
    @media screen and (max-width: 640px) {
      margin: -16px -16px 8px;
    }
  }
  .label-form {
    margin-bottom: 24px !important;
  }

  .sub-title-form-spid {
    margin-bottom: 8px;
  }

  #it-ar-identification-label-form {
    span > span {
      display: block;

      @include medium-devices-tablet {
        display: inline;
      }
    }
  }

  .select-document {
    color: #3a3a3a;
    padding: 0.625rem 0.625rem 0.625rem 0.8125rem;
    display: block;
    width: 100%;
    border: 1px solid $borderInput;
    border-radius: 0.25rem;
    font-size: 1rem;
    height: 2.75rem;
    font-weight: 500;
    & label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 75%;
    }
  }
  .opened-list {
    // position: fixed;
    // transform: translate(0, -50%);
    // z-index: 3;
    // max-width: calc(100% - 30px);
    // top: 50%;
    position: absolute;
    z-index: 3;
    max-width: 100%;
    top: 60%;

    // @include landscape-mobile {
    //   top: 0;
    //   position: absolute;
    //   transform: translate(0, 0);
    //   max-width: 42.5rem;
    // }
  }
  .otherwise {
    line-height: 3.125rem;
    padding: 0 1.5rem;
    align-items: center;
  }
  .hr-left {
    margin-right: 1.06rem;
  }
  .hr-right {
    margin-left: 1.06rem;
  }
  .btn-upload {
    padding-top: 0;
    padding-bottom: 0;
    min-width: 10rem;
    max-width: 10rem;
    padding-left: 1.4rem;
    left: 50%;
    font-size: 14px;
    color: #222222;
    display: block;
    font-weight: bold;
    transform: translate(-50%, 0);
    @include medium-devices-tablet {
      left: 0;
      transform: translate(0, 0);
    }
    @include ie11support {
      font-size: 0;
      span {
        font-size: 1rem;
      }
    }
  }
  .accordion-document {
    padding-top: 0.4rem;
    background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
      right 0.2rem center/2rem 1.125rem;
    background-size: 18px;
    @include landscape-mobile {
      background-size: 18px;
    }
  }

  #it-ar-identification-form-group1 {
    min-height: unset;
    margin-bottom: 24px;
    margin-top: -4px;

    .documentType-label {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }

    .documentType-buttons-container {
      display: flex;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .documentType-button {
        width: 177px;
        margin-right: 10px;
        padding: 15px 16px;
        border: 1px solid #f0f2f4;
        border-radius: 5px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
        white-space: nowrap;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        background-color: #fff;
        color: #222222;

        &.selected {
          border-color: #bed62f;
          background-color: #f2f7d5;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.dark_label {
  text-align: left !important;
  float: left;
  padding: 0;
  span {
    font-weight: 400;
    line-height: 21px;
  }
}
.light_span {
  color: $primaryTextColor;
  line-height: 1.3rem;
  text-align: left;
  opacity: 0.4;
}

.rowflex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

div#it-ar-identification-col1 {
  display: flex;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  width: 100%;
}

input#inputGroupFile04::-webkit-file-upload-button {
  visibility: hidden;
}

#it-ar-identification-lblUploadNow {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.32px;
  color: #222222;
  font-weight: 900;
  font-family: "Roboto";
}

.label-upload-later.light_span {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.28px;
  color: #999999;
  font-family: "Roboto";
  font-weight: 300;
  opacity: 1;
  margin-top: -4px;
}

@media screen and (max-width: 640px) {
  #it-ar-identification-lblUploadNow {
    font-size: 14px;
    letter-spacing: 0.28px;
  }

  .label-upload-later.light_span {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.24px;
  }
}

@import "./partials/calculateFiscalCodePopup/calculateFiscalCodePopup";
@import "./partials/gender/gender";
@import "./partials/calculateFiscalCodeButton/calculateFiscalCodeButton";

.fiscalCode {
  .conferma-div {
    margin-top: 24px;
  }
  .non-conferma-div {
    @include medium-devices-tablet {
      margin-top: 69px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 64px;
    }
  }

  .se-non-e-corretto-co {
    height: 14px;
    width: 330px;
    color: #222222;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.24px;
    line-height: 14px;
    margin: 12px 0 0 2px;
    @include landscape-android {
      margin: 12px 0 0 2px !important;
      word-wrap: break-word;
      width: 100%;
    }
    @include landscape-iphone {
      margin: 12px 0 0 7px;
    }
  }
  .form-control:disabled,
  .form-control[readonly] {
    background: transparent;
  }
  div#it-ar-fiscalCode-form-group1[readonly] {
    opacity: 0.5;
  }

  .cf-with-calc-block {
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.generated-cf {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #222222;
  margin-bottom: 0;
  margin-top: 8px;
}

.form-group.form-group-fc {
  min-height: 4rem;
}

@import "mixin";
.btn {
  &.btn_primary {
    border-radius: 7px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #bed62f;
    border: 0;
    font-size: 0.875rem;
    font-weight: 700;
    color: #222222;
    @include medium-devices-tablet {
      min-width: 10rem;
    }
    &[disabled] {
      background: #def199;
    }
    &:focus {
      box-shadow: none !important;
    }
    &.orange {
      color: #fff;
      width: 10rem;
      background: linear-gradient(45deg, #ff8e0d 0%, #ffaf1e 100%);
      &[disabled] {
        background: linear-gradient(45deg, #ff8e0d, #ffaf1e);
      }
      .icon-btn-right {
        margin-left: 0;
        @include medium-devices-tablet {
          display: none;
        }
      }
    }
  }

  &.btn-icon-right {
    padding-left: 2rem;
    padding-right: 0.7rem;
  }

  &.btn-icon-left {
    padding-left: 0.7rem;
    padding-right: 2rem;
  }

  &.btn-link {
    height: 3.125rem;
    border-radius: 1.56rem;
    color: $greyTextColor;
    border: 0;
    font-size: 1rem;
    font-weight: 500;
    @include medium-devices-tablet {
      min-width: 10rem;
    }
  }

  &.btn-upload {
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 2.06rem;
    padding-right: 2.06rem;
    @include landscape-mobile {
      padding-left: 1.625rem;
      padding-right: 1.625rem;
    }
    &.upload {
      width: 10.88rem;
    }
    & .icon-btn-left {
      margin-right: 0.625rem;
    }
  }

  &.btn-upload-later {
    height: 2.25rem;
    color: #222222;
    font-size: 14px;
    font-weight: bold;
    text-transform: none;
    text-decoration: underline;
    cursor: pointer;
  }

  .icon-btn-right {
    float: right;
    margin-left: 0.94rem;

    img {
      vertical-align: top;
      @include ie11support {
        vertical-align: middle;
      }
    }
  }

  .icon-btn-left {
    float: left;
    img {
      vertical-align: top;
      width: 1.5rem;
      height: 1.5rem;
      @include ie11support {
        vertical-align: middle;
      }
    }
  }
  .btnLabelback {
    font-size: 0.875rem;
    color: #222222;
  }

  .icon-btn-center {
    float: left;
    margin-right: 0.94rem;

    img {
      vertical-align: top;
    }
  }
}
button:focus {
  outline: none !important;
  @include sisal-box-shadow(none !important);
}

.wizard-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .wizard {
    .step-container,
    .step-actions-container {
      @include medium-devices-tablet {
        max-width: 501px !important;
      }

      @include desktop {
        max-width: 1002px !important;
      }
    }

    > .container:nth-child(1) {
      padding-top: 32px;

      @include landscape-mob {
        padding-top: 15px;
      }
    }

    .fiscalCode .custom-p-link {
      @include landscape-mob {
        padding-top: 0.1rem;
      }
    }

    .accordion .cont-accordion .accordion-header {
      @include landscape-mob {
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }

    //mod progressBarDots

    &.wizard--A,
    &.wizard--B {
      > .container:nth-child(1) {
        padding-top: 92px;

        @include landscape-mob {
          padding-top: 80px;
        }

        @include desktop {
          padding-top: 32px;
        }
      }

      .title-form {
        display: none;
      }
    }

    &.wizard-inizio {
      padding-bottom: 0;
    }
  }

  .wizard__top-padding {
    height: 16px;

    @include medium-devices-tablet {
      height: 3.5vh;
    }
  }
}

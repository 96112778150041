.residence {
  .linkResidence {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    padding-left: 10px;
    color: #222222;
  }
  .nation {
    .opened-nation-list {
      // top: 2.44rem;
      @include medium-devices-tablet {
        // top: 3.31rem;
      }
    }
  }
  ul.list-group.opened-list {
    overflow-y: scroll;
    width: calc(100% - 10px);
  }
  .list-group {
    background-color: white;
  }
  div#it-ar-residence-form-group6 {
    min-height: 3rem;
  }

  .form-check {
    .linkResidence {
      .checkmark {
        @include medium-devices-tablet {
          margin-top: 0;
        }
      }
    }
  }

  .address-input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

#it-ar-domicile-dontWorryForSerenade {
  font-size: 12px !important;
  font-weight: 400;
  font-family: "Roboto";
  line-height: 18px;
}

@import "./tooltip/tooltip";

.container.ocrForm {
  .row {
    &.is-invalid {
      .form-control-label.label-date {
        color: $primaryErrorColor;
        font-weight: 500;
      }
      .input-group .form-control {
        border-bottom: 0.125rem solid $primaryErrorColor;
      }
    }
    &.is-present {
      .form-control-label.label-date {
        color: $textAlreadyPresent;
        font-weight: 500;
      }
      .input-group .form-control {
        border-bottom: 0.125rem solid $textAlreadyPresent;
      }
    }
    .form-group-relative {
      position: relative;
      .form-group {
        .question-mark {
          position: absolute;
          top: 14px;
          right: 14px;
          width: 16px;
          height: 16px;
          background: url("#{$images-path}/svg/icon/question-mark.svg")
            no-repeat center transparent;
          cursor: pointer;
          z-index: 1;
        }
      }
      .town ~ {
        .messageYellow {
          &.text-label-ocr {
            margin-left: 0;
            padding-left: 0;
            font-size: 1rem;
          }
        }
      }
    }
  }

  .cont-date-relative {
    position: relative;
  }

  .opened-list {
    z-index: 3;
    &.issuers-list {
      position: absolute;
      max-width: 100%;
      top: 60%;
    }
  }

  #it-ar-ocrform-form-group2 {
    .messageYellow {
      &.text-label-ocr {
        position: absolute;
        top: initial;
        bottom: 10px;
      }
    }
  }

  #it-ar-ocrform-form-group3 {
    .messageYellow {
      &.text-label-ocr {
        position: absolute;
        top: initial;
        bottom: 10px;
      }
    }
  }

  .text-release-entity {
    color: $suggestBtn;
    font-family: Roboto;
    font-weight: 300;
    font-size: 0.75rem;
    @include medium-devices-tablet {
      font-size: 1rem;
    }
  }

  .box-side-datepicker {
    margin-right: 3.75rem;
    width: 4.375rem;
    margin-left: 0.94rem;
    margin-top: 1.88rem;
  }
  .select-customer {
    // margin-bottom: 2rem;
    &.selectType {
      color: #3a3a3a;
      padding: 0.625rem 0.625rem 0.625rem 0.8125rem;
      display: block;
      width: 100%;
      border: 1px solid $borderInput;
      border-radius: 0.25rem;
      font-size: 1rem;
      height: 2.75rem;
      font-weight: 500;
    }
  }
  .accordion-ocr {
    padding-top: 0.4rem;
  }

  .document-dates-container {
    display: flex;
    justify-content: space-between;

    & > div {
      max-width: calc(50% - 12px);

      &:first-child {
        margin-right: 24px;
      }
    }
  }
}

@keyframes animated-loading {
  0% {
    @include sisal-transform(rotate(-220deg) scale(0.8));
    visibility: visible;
    opacity: 0;
  }
  8.5% {
    @include sisal-transform(rotate(-270deg) scale(1));
    opacity: 1;
  }

  15% {
    @include sisal-transform(rotate(-320deg) scale(0.8));
    opacity: 0;
  }

  100% {
    @include sisal-transform(rotate(0deg) scale(1));
    opacity: 0;
  }
}

.container.ocrForm .messageYellow.text-label-ocr {
  font-size: 0.75rem !important;
}

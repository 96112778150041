#typ-ccm-otp-step {
  .popup-modal-body {
    position: relative;

    .form-group .form-control.is-invalid:not(:focus) ~ label {
      top: 0.7rem;
    }

    .otp-popup-verified-overlay {
      position: absolute;
      z-index: 99;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .otp-popup-verified {
        background: rgba(245, 251, 224, 0.8);
        backdrop-filter: blur(10px);
        border-radius: 10px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .otp-popup-verified-img {
          width: 45px;
          height: 45px;
          margin-bottom: 10px;

          img {
            width: 100%;
          }
        }

        .otp-popup-verified-title {
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 120%;
          display: flex;
          align-items: center;
          text-align: center;
          color: #0a7d41;
        }
      }
    }

    .modal-body-text {
      margin-bottom: 32px;
      font-size: 14px;
      line-height: 18px;
    }

    .message-label {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin-top: 4px;

      &.resend {
        color: #0a7d41;
      }

      &.invalid {
        color: #e32219;
      }
    }

    .otp-resend {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 16px;

      @include medium-devices-tablet {
        margin-bottom: 24px;
      }

      .otp-resend-link {
        margin-left: 4px;
        color: #222;
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
}

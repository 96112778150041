@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-Black.woff2") format("woff2"),
    url("#{$font-path}/Muli-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-BlackItalic.woff2") format("woff2"),
    url("#{$font-path}/Muli-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-Bold.woff2") format("woff2"),
    url("#{$font-path}/Muli-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-BoldItalic.woff2") format("woff2"),
    url("#{$font-path}/Muli-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-ExtraBold.woff2") format("woff2"),
    url("#{$font-path}/Muli-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-ExtraBoldItalic.woff2") format("woff2"),
    url("#{$font-path}/Muli-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-ExtraLight.woff2") format("woff2"),
    url("#{$font-path}/Muli-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-ExtraLightItalic.woff2") format("woff2"),
    url("#{$font-path}/Muli-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-Regular.woff2") format("woff2"),
    url("#{$font-path}/Muli-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-Italic.woff2") format("woff2"),
    url("#{$font-path}/Muli-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-Light.woff2") format("woff2"),
    url("#{$font-path}/Muli-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-LightItalic.woff2") format("woff2"),
    url("#{$font-path}/Muli-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-SemiBold.woff2") format("woff2"),
    url("#{$font-path}/Muli-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src:
    url("#{$font-path}/Muli-SemiBoldItalic.woff2") format("woff2"),
    url("#{$font-path}/Muli-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

.accordion {
  .cont-accordion {
    .accordion-header {
      display: flex;
      width: 100%;
      padding-top: 17px;
      padding-bottom: 17px;
      color: #222222;
      font-family: Muli;
      font-weight: 900;
      font-size: 1rem;
      background: url("#{$images-path}/svg/icon/arrow-down-black.svg") no-repeat
        right center;
      background-size: 12px;
      &.active,
      &.error-form {
        color: $formTitle;
        background: url("#{$images-path}/svg/icon/arrow-up-green.svg") no-repeat
          right center;
        background-size: 12px;
        ~ .collapse-accordion {
          @include sisal-transition(all 0.5s);
          height: 100%;
          overflow: visible;
          border-bottom: 0.125rem solid $formTitle;
          padding-bottom: 1.65rem;
        }
        .icon-accordion {
          &.bonus {
            background: url("#{$images-path}/svg/icon/riepilogo/bonus-active.svg")
              no-repeat center;
          }
          &.terms {
            background: url("#{$images-path}/svg/icon/riepilogo/termini-condizioni-active.svg")
              no-repeat center;
          }
          &.recharge-limit {
            background: url("#{$images-path}/svg/icon/riepilogo/limiti-ricarica-active.svg")
              no-repeat center;
          }
          &.document {
            background: url("#{$images-path}/svg/icon/riepilogo/documento-active.svg")
              no-repeat center;
          }
          &.access-data {
            background: url("#{$images-path}/svg/icon/riepilogo/dati-accesso-active.svg")
              no-repeat center;
          }
          &.contacts {
            background: url("#{$images-path}/svg/icon/riepilogo/contacts-active.svg")
              no-repeat center;
          }
          &.residence-domicile {
            background: url("#{$images-path}/svg/icon/riepilogo/residenza-active.svg")
              no-repeat center;
          }
          &.personal-data {
            background: url("#{$images-path}/svg/icon/riepilogo/dati-personali-active.svg")
              no-repeat center;
          }
        }
      }
      p {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
      }

      .icon-accordion {
        display: inline-block;
        margin-right: 0.94rem;
        width: 1.5rem;
        height: 1.5rem;

        &.bonus {
          background: url("#{$images-path}/svg/icon/riepilogo/bonus.svg")
            no-repeat center;
        }
        &.terms {
          background: url("#{$images-path}/svg/icon/riepilogo/termini-condizioni.svg")
            no-repeat center;
        }
        &.recharge-limit {
          background: url("#{$images-path}/svg/icon/riepilogo/limiti-ricarica.svg")
            no-repeat center;
        }
        &.document {
          background: url("#{$images-path}/svg/icon/riepilogo/documento.svg")
            no-repeat center;
        }
        &.access-data {
          background: url("#{$images-path}/svg/icon/riepilogo/dati-accesso.svg")
            no-repeat center;
        }
        &.contacts {
          background: url("#{$images-path}/svg/icon/riepilogo/contacts.svg")
            no-repeat center;
        }
        &.residence-domicile {
          background: url("#{$images-path}/svg/icon/riepilogo/residenza.svg")
            no-repeat center;
        }
        &.personal-data {
          background: url("#{$images-path}/svg/icon/riepilogo/dati-personali.svg")
            no-repeat center;
        }
      }
    }

    .collapse-accordion {
      height: 0;
      overflow: hidden;
      border-bottom: 0.125rem solid $borderInput;
      .contacts .col-prefix .list-group.opened-list {
        @include landscape-mobile {
          transform: translate(0, 0);
        }
      }
      .col-prefix {
        min-width: 7rem;
        position: relative;
        .form-control {
          background: url("#{$images-path}/svg/icon/arrow-down-black.svg")
            no-repeat right 0.2rem center/2rem 1.125rem;
          @include landscape-mobile {
            background-size: 1.5rem;
          }
        }
      }
      .securityQuestion .list-group.opened-list {
        @include landscape-mobile {
          top: 0;
          position: absolute;
          transform: translate(0, 0) !important;
          max-width: 680px;
          left: unset;
        }
      }
      .btn-verify {
        max-width: 10rem;
      }
      .identification .select-document {
        margin-top: 0.94rem;
        margin-bottom: 2.25rem;
        @include medium-devices-tablet {
          margin-top: 2.25rem;
          margin-bottom: 3.125rem;
        }
      }
      .accordion-form {
        // margin-top: 1.625rem;
        margin-top: 0;

        @include landscape-mob {
          margin-top: 0;
        }

        &.col-prefix {
          margin-top: 1.25rem;
          margin-bottom: 2.06rem;
        }
      }

      // div#it-ar-contacts-form-group-nation {
      //   margin-top: 0;
      // }

      .residence,
      .domicile {
        .accordion-form {
          margin-top: 0.75rem;
          @include landscape-mob {
            margin-top: 0;
          }
        }
      }
      .identification,
      .welcomeBonus {
        .opened-list {
          position: fixed;
          transform: translate(0, -50%);
          z-index: 3;
          max-width: calc(100% - 30px);
          top: 50%;
          left: 0.94rem;
          @include landscape-mobile {
            top: 0;
            position: absolute;
            transform: translate(0, 0);
            max-width: 42.5rem;
            left: 0;
          }
        }
      }

      .welcomeBonus {
        .opened-list {
          position: absolute;
          max-width: 100% !important;
          left: 0 !important;
          top: calc(100% - 1px);
          transform: none;
          z-index: 3;
        }
      }

      .ocrForm .issuers-list {
        position: fixed;
        transform: translate(0, -50%);
        z-index: 3;
        max-width: calc(100% - 30px);
        top: 50%;
        left: 0.94rem;
        @include landscape-mobile {
          top: 0;
          position: absolute;
          transform: translate(0, 0);
          max-width: 42.5rem;
          left: 0;
        }
      }

      .welcomeBonus {
        .opened-list {
          max-width: calc(100% - 30px);
          left: 0.94rem;
          @include desktop {
            max-width: 42.5rem;
          }
        }
      }
      .check-sex {
        margin-top: 0.94rem;
        @include medium-devices-tablet {
          margin-top: 0;
        }
        .opened-list {
          max-width: 6.25rem;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          position: fixed;
          @include landscape-mobile {
            transform: translate(0.31rem, 0);
            left: 0;
            position: absolute;
            top: 0;
          }
        }
        .dropdown-sex {
          background: url("#{$images-path}/svg/icon/arrow-down-up.svg")
            no-repeat right 0.2rem center/2rem 1.125rem;
          padding-top: 0.4rem;
          text-align: left;
          background-size: 18px;
          @include landscape-mobile {
            background-size: 18px;
          }
        }
      }
    }
  }

  .cont-accordion:first-child {
    .accordion-header {
      padding-top: 0 !important;
    }
  }

  .inputDate .container-date .input-group input {
    height: 2.75rem;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
    .form-control {
      min-width: 100%;
    }
    .container-date {
      .input-group-prepend {
        min-width: 100%;
      }
    }
    .accordion-ocr {
      background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
        right 0.2rem center/2rem 1.125rem;
      background-size: 18px;
      @include landscape-mobile {
        background-size: 18px;
      }
    }
    .question {
      .form-control {
        background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
          right 0.2rem center/2rem 1.125rem;
        background-size: 18px;
        @include landscape-mobile {
          background-size: 18px;
        }
      }
    }
  }
  .invalid-alert {
    position: relative;
  }

  .inputDate .container-date.is-invalid .input-group .invalid-feedback {
    position: relative;
    bottom: 0;
    margin-left: 0;
  }
  .loadedDocument {
    padding-left: 0.31rem;
  }
}

.flussoWizard {
  .accordion {
    .cont-accordion {
      .collapse-accordion {
        .birthplace,
        .contacts {
          margin-top: 10px;
        }
        #it-ar-contacts-tab-email {
          margin-top: 10px !important;
        }
        .accordion-form {
          margin-top: 0 !important;
          .col-tel {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}

div#it-ar-summary-collapse-accordion6,
div#it-ar-summary-collapse-accordion7,
div#it-ar-summary-collapse-accordion8 {
  padding-bottom: 0;
}

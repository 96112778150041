#typ-ccm {
  .popup-modal-body {
    padding: 16px 16px 32px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;

    @include medium-devices-tablet {
      padding: 16px 16px 8px;
    }

    .form-group {
      margin-bottom: 32px;
      min-height: unset;

      .form-control {
        &.is-required {
          border-color: #fabb00;

          ~ .form-control-label {
            color: #fabb00;
            font-weight: 500;
          }
        }
        &.is-invalid {
          border-color: #e32219;

          ~ .form-control-label {
            color: #e32219;
            font-weight: 500;
          }
        }
      }

      .feedback-container {
        .invalid-feedback {
          display: block;
          padding: 0;
          padding-left: 5px;
          margin-top: 8px !important;
          margin-bottom: 0;
          min-height: unset;
          line-height: 14px;
        }

        &.is-required .invalid-feedback {
          color: #fabb00;
        }
      }
    }

    .sub-note {
      margin-bottom: 8px;

      @include medium-devices-tablet {
        margin-bottom: 0;
      }

      b {
        font-weight: 700;
      }
    }
  }

  .popup-modal-footer button {
    min-width: 150px;

    @include medium-devices-tablet {
      padding: 9px 24px;
    }
  }

  @import "./typcModalEmailStep/typcModalEmailStep";
  @import "./typcModalOtpStep/typcModalOtpStep";
  @import "./typcModalFinalStep/typcModalFinalStep";
}

.wizard-btn-container {
  .wizard-btn {
    margin-right: 1.125rem;
    @include medium-devices-tablet {
      margin-right: 0;
    }
    .btn.btn-icon-right {
      padding: 0;
      width: 3.125rem;
      .icon-btn-right {
        margin: 0.81rem;
        @include medium-devices-tablet {
          margin: 0;
          margin-left: 0.94rem;
        }
        img {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
      @include medium-devices-tablet {
        padding-left: 2rem;
        padding-right: 0.7rem;
        width: auto;
      }
    }
  }

  .wizard-btn-back,
  .wizard-btn {
    span {
      display: none;
      @include medium-devices-tablet {
        display: inline;
      }
    }
  }

  &.stickyClass {
    margin-top: 0;
    position: absolute;
    padding-top: 0 !important;
    bottom: 3.688rem;
    left: 50%;
    transform: translate(-50%, 0);
    top: auto;

    @include medium-devices-tablet {
      bottom: 9rem;
    }
  }

  .btn-back {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #bed62f;
    border-radius: 8px;
    color: #262c2f;
  }

  .btn-next {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px 0px 16px;
    border: none;

    width: 113px;
    height: 40px;

    background: #bed62f;
    border-radius: 8px;

    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #262c2f;

    &:disabled {
      background: #deea96;
      color: #929596;
    }

    span {
      display: inline;
    }
  }
}

.notshow {
  display: none !important; //select visible-invisible
}

div#it-ar-wizardbutton-stepper {
  display: none !important;
}

.flussoButt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem !important;
  flex-direction: column;
}

.flussoButt .btn.btn_primary {
  width: 358px !important;
  height: 44px;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  line-height: 0 !important;
}

.flussoButt .col-fluss1 {
  display: none !important;
}

.lbl_account {
  text-align: center;
  width: 100%;
  margin-bottom: 17px;
}

.lbl_account p {
  font-family: Muli;
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 20px;
  color: $blackColor;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .flussoButt .wizard-btn span {
    display: block !important;
  }
  .flussoButt .btn.btn_primary {
    width: 100% !important;
    max-width: 100% !important;
  }

  .flussoButt .wizard-btn {
    margin-right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flussoButt .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-top: 3px;
  }

  .flussoButt button.btn.btn_primary {
    margin: 12px 0;
  }

  .flussoButt {
    margin-top: 0 !important;
    margin-bottom: 8px;
  }
  .lbl_account p {
    line-height: 9px;
  }
}

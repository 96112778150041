@import "mixin";
.form-check {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    //cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    cursor: pointer;
    left: 0;
    background: url("#{$images-path}/svg/icon/checkbox_24px_sharp.svg")
      no-repeat;
    height: 22px;
    width: 22px;
    top: 2px;
    background-size: contain;
    @include medium-devices-tablet {
      margin-top: 0.25rem;
      height: 13px;
      width: 13px;
    }
    @include desktop {
      margin-top: 0rem;
      height: 24px;
      width: 24px;
    }
    &.term {
      top: 0;
      @include medium-devices-tablet {
        top: 0.175rem;
      }
      &.aligned-checkmark {
        top: 0.06rem;
        @include medium-devices-tablet {
          top: 0;
        }
        @include desktop {
          top: 0.15rem;
        }
      }
    }
  }

  input:checked ~ .checkmark {
    background: url("#{$images-path}/svg/icon/checkbox_24px_sharp_checked_y.svg")
      no-repeat;
    background-size: cover;
  }

  .form-check-label {
    cursor: pointer;
    padding-left: 1.06rem;
    font-size: 0.75rem;
    vertical-align: super;
  }
}

.form-check-sex {
  input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }
  .form-check-label {
    color: #222222;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    height: 48px;
    //width: 164px;
    width: 155px;
    background-color: $whiteColor;
    @include sisal-box-shadow(0 0.125rem 1.25rem 0 $boxShadowGrey);
    border-radius: 5px;
    padding-left: 0;
    line-height: 47px;
    cursor: pointer;

    @include landscape-smallMobile {
      width: 150px;
    }

    @include landscape-extraSmall {
      width: 140px;
    }
  }
  .selected {
    border-radius: 5px;
    background: #bed62f;
    @include sisal-box-shadow(none);
  }

  &.form-check-inline {
    margin: 0;
    /*
    width: 45%;
    margin: auto;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
    @include medium-devices-tablet {
      max-width: 100%;
      width: auto;
      margin: 0;
      margin-right: 2.5rem;
    }
    */
  }
  &.form-checkFirst {
    margin-right: 16px;
  }
}

div#it-ar-securityquestion-col2 {
  @include landscape-iphone {
    .form-checkFirst {
      @include landscape-smallMobile {
        margin-right: 12px;
      }

      @include landscape-extraSmall {
        margin-right: 10px;
      }
    }
  }
}

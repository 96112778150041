.sign-up-button-container {
  display: flex;
  justify-content: space-between;

  .btn-back {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #bed62f;
    border-radius: 8px;
    color: #262c2f;
    min-width: unset;
  }

  .sign-up-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    align-items: center;
    padding: 0px 16px;
    border: 1px solid rgba(34, 34, 34, 0.12);

    height: 40px;

    background: #bed62f;
    border-radius: 8px;

    font:
      700 14px/1 "Roboto",
      sans-serif;
    color: #262c2f;

    &:disabled {
      background: #f2f7d5;
      color: #909090;

      .sign-up-button__icon-next {
        filter: invert(62%) sepia(4%) saturate(15%) hue-rotate(332deg)
          brightness(91%) contrast(91%);
      }
    }
  }

  .sign-up-button__icon-next {
    width: 12px;

    @include medium-devices-tablet {
      display: unset;
    }
  }
}

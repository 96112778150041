.ocr-data-summary-container {
  .ocr-summary-info-block {
    display: flex;
    column-gap: 8px;
    align-items: center;
  }

  .summary-element-title {
    font:
      700 16px "Muli",
      sans-serif;
  }
}

@import "~bootstrap/scss/bootstrap.scss";

$font-path: "/font" !default;
$images-path: "/images" !default;

@import "sass/variables";
@import "sass/input";
@import "sass/button";
@import "sass/_fontRoboto.scss";
@import "sass/_fontMuliBlack.scss";
@import "sass/_form.scss";
@import "sass/_text.scss";
@import "sass/_checkBox.scss";
@import "sass/_customSelect.scss";
@import "sass/_fileInput.scss";
@import "sass/_accordion.scss";
@import "sass/_cardBonus";

// Component //
@import "component/thankYouPage/thankYouPage";
@import "component/thankYouPagePdv/thankYouPagePdv";
@import "component/activeAccount/activeAccount";
@import "component/questionarioPep/questionarioPep";
@import "component/contacts/contacts";
@import "component/birthplace/birthplace";
@import "component/residence/residence";
@import "component/domicile/domicile";
@import "component/document/document";
@import "component/elementForm/inputDate/inputDate";
@import "component/elementForm/promoCode/promocode";
@import "component/elementForm/town/town";
@import "component/elementForm/messageYellow/messageYellow";
@import "component/elementForm/messageAlert/messageAlert";
@import "component/elementForm/nation/nation";
@import "component/elementForm/preFooter/preFooter";
@import "component/elementForm/loadingGif/loadingGif";
@import "component/elementForm/questionarioPepModal/questionarioPepModal";
@import "component/elementForm/moreInfoModal/moreInfoModal";
@import "component/elementForm/backBtn/backBtn";
@import "component/elementForm/submitBtn/submitBtn";
@import "component/errorCardsPage/errorCardsPage";
@import "component/activeAccount/activateAccountErrorPage/activateAccountErrorPage";
@import "component/errorCard/errorCard";
@import "component/errorCardBonusPage/errorCardBonusPage";
@import "component/errorCardOcrPage/errorCardOcrPage";
@import "component/errorPage/errorPage";
@import "component/fiscalCode/fiscalCode";
@import "component/header/header";
@import "component/identification/identification";
@import "component/ocrForm/ocrForm";
@import "component/progressBarSteps/progressBarSteps";
@import "component/rechargeLimit/rechargeLimit";
@import "component/sex/sex";
@import "component/summary/summary";
@import "component/termsAndConditions/termsAndConditions";
@import "component/userData/userData";
@import "component/welcomeBonus/welcomeBonus";
@import "component/welcomeBonus/bonusDetailsModal/bonusDetailsModal";
@import "component/wizardBtn/wizardBtn";
@import "component/wizard/wizard";
@import "component/loader/loader";
@import "component/elementForm/fullPageLoader/fullPageLoader";
@import "component/elementForm/linkPdv/linkPdv";
@import "component/elementForm/suggestionPassword/suggestionPassword";
@import "component/personalData/personalData";
@import "component/inizio/inizio";
@import "component/VerificationEmail/verificationEmail";
@import "component/prohibitedToMinorsBanner/prohibitedToMinorsBanner";
@import "component/spidButton/spidButton";
@import "component/registrationTypeSelection/registrationTypeSelection";
@import "component/spidRegistration/spidRegistration";
@import "component/commons/background/background";
@import "component/infoWithPdf/infoWithPdf";
@import "component/documentViewer/documentViewer";
@import "component/popupModal/popupModal";
@import "component/leaveConfirmPopup/leaveConfirmPopup";
@import "component/commons/alertBox/alertBox";
@import "component/jumio/jumio";
@import "component/commons/alertBox/alertBox";
@import "component/commons/singleInputDate/singleInputDate";
@import "component/commons/subNote/subNote";
@import "component/signButton/signButton";
@import "component/termsAndConditions/privacyInfoModal/privacyInfoModal";
@import "component/spidFiscalCode/spidFiscalCode";
@import "component/unmatchingPersonalDataPage/unmatchingPersonalDataPage";
@import "component/inputFields/sexSelectInputField/sexSelectInputField";
@import "component/loyaltyBanner/loyaltyBanner";
@import "component/loyaltyPopup/loyaltyPopup";
@import "component/loyaltyCard/loyaltyCard";
@import "component/registrationOCRSelection/registrationOCRSelection";
@import "component/ocrCallback/ocrCallback";
@import "component/ocrDataSummary/ocrDataSummary";
@import "component/ocrPage/ocrPage";
@import "component/documentSelectionPage/documentSelectionPage";

html {
  font-size: 16px;
  min-height: 100vh;
  @include ie11support {
    overflow-x: hidden;
  }
}
@import "component/securityQuestion/securityQuestion";

body {
  background: $primaryBgColor;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: $textSelectColor;
  @include font-smoothing(antialiased);
  &.no-scroll {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @include landscape-mobile {
      position: inherit;
      overflow: auto;
    }
    @include landscape-iphone {
      position: inherit;
      overflow: auto;
    }
    @include landscape-android {
      position: inherit;
      overflow: auto;
    }
    @include ie11support {
      height: auto;
      width: 100vw;
      overflow-x: hidden;
    }
  }
}

h1 {
  font-weight: 500;
}

input,
input:focus {
  caret-color: #212529;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: none;
}
.grecaptcha-badge {
  display: none !important;
}

.recharge-limit-evaluation-modal {
  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .text {
    font-size: 14px;
  }

  .max-limit-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    font-size: 14px;
    border: 1px solid #f0f2f4;
    border-radius: 5px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
    padding: 1rem;
  }

  .amount {
    font-weight: 500;
  }
}

@import "./rechargeLimitEvalutationModal/rechargeLimitEvaluationModal.scss";

.recharge-limit {
  .recharge-buttons-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    margin-bottom: 2rem;
  }

  .btn-recharge {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #f0f2f4;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #222222;

    &.btn-selected {
      background: #f2f7d5;
      border: 1px solid #bed62f;
    }
  }

  div#it-ar-rechargelimit-row1 {
    margin-bottom: 12px;
  }

  .setPadding {
    padding-left: 30px;
    @include medium-devices-tablet {
      padding-left: 3rem;
    }
  }
  .lbl-position {
    height: 1.88rem;
    max-width: 13.13rem;
    color: $formLabel;
    font-family: Roboto;
    font-weight: 300;
    line-height: 1rem;
    font-size: 0.87rem;
    padding-top: 0.625rem;
    padding-left: 0.94rem;
    text-align: left;
    text-align: right;
    line-height: 3.125rem;
    padding: 0;
    @include medium-devices-tablet {
      font-size: 1.6rem;
    }
    @include ie11support {
      margin-right: 1rem;
    }
  }
  .select-customer {
    &.selectType {
      color: #3a3a3a;
      padding: 0.625rem 0.625rem 0.625rem 0.8125rem;
      display: block;
      width: 100%;
      border: 1px solid $borderInput;
      border-radius: 0.25rem;
      font-size: 1rem;
      height: 2.75rem;
      font-weight: 500;
    }
  }
  .opened-list {
    overflow: auto;
  }
  .currency {
    color: #3a3a3a;
    left: 30px;
    font-weight: 500;
    position: absolute;
    top: 0.67rem;
    font-size: 1rem;
    @include ie11support {
      top: 0.67rem;
    }
    &.notSelectedYet {
      display: none;
    }
  }
  button:focus {
    outline: none;
  }
}

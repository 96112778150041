.welcomeBonus {
  .vedi-altri-bonus-dis {
    color: #222222;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 19px;
    margin-top: 11px;

    @include medium-devices-tablet {
      text-align: left;
      margin-left: 18px;
    }
  }

  .rotate {
    transform: rotate(180deg);
  }
}

.background-sisal {
  position: absolute;
  top: 50px;
  z-index: -1;
  height: calc(100dvh - 50px); // 100dvh minus header
  width: 100dvw;
  background: none;

  &--show-on-mobile {
    background:
      url("#{$images-path}/svg/background-mobile-top.svg") no-repeat,
      url("#{$images-path}/svg/background-mobile-bottom.svg") no-repeat right
        bottom;
  }

  @include medium-devices-tablet {
    background:
      url("#{$images-path}/svg/background-desktop-top-left.svg") no-repeat,
      url("#{$images-path}/svg/background-desktop-bottom-right.svg") no-repeat
        right bottom;
  }
}

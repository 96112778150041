.preFooter {
  &.preFooter-desktop-container {
    display: none;
    width: 500px;
    margin: 0 auto 60px auto;

    @include medium-devices-tablet {
      display: block;
    }
  }

  .preFooter-desktop-text {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .preFooter-desktop-body {
    display: flex;
    justify-content: space-between;
  }
}

.calculate-fiscal-code-button {
  all: unset;
  color: $textSelectColor;
  padding-top: 0.31rem;
  cursor: pointer;
  text-decoration: underline;
  width: max-content;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.28px;
  @include medium-devices-tablet {
    font-size: 14px;
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

.questionario-pep-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  padding-top: 157px;
  z-index: 9;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
  }
  .pep-modal {
    max-width: 314px;
    width: 100%;
    min-height: 196px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(25, 29, 58, 0.1);
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    .conferma-header {
      height: 60px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      background: #2f3c43;
      h4 {
        font-family: Muli;
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.0048em;
        color: #ffffff;
        margin: 0;
      }
      button {
        margin-left: auto;
        box-shadow: none;
        border: 0;
        background: none;
        padding: 0;
        img {
          width: 23px;
        }
      }
    }
    .block {
      padding: 24px 16px;
      text-align: center;
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #222222;
        margin: 0;
        margin-bottom: 16px;
      }
      button {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #bed62f;
        border-radius: 7px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        color: #222222;
        border: 0;
        margin: 0 auto;
        padding: 0 16px;
      }
      a {
        margin: 0 auto;
        margin-top: 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        color: #222222;
        text-decoration: underline;
        display: inline-block;
        cursor: pointer;
      }
    }
  }
}

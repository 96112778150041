.document-selection-container {
  .document-selection-head-form {
    margin: 0 0 24px 0;

    &__title {
      margin: 0 0 12px 0;
    }

    &__description {
      margin: 0;
    }
  }
}

.wizardInizio {
  position: relative;
  overflow: hidden;
  font-family: Roboto;
  min-height: calc(100vh - 3.125rem - 2px);
  margin-top: 15vh;

  .InizioBack {
    width: 100%;
    .inizio_card {
      max-width: 475px;
      margin: 0 auto;
      border: 1px solid #f0f2f4;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.04);
      border-radius: 15px;
      padding: 24px 16px;
      background: url("#{$images-path}/card_bg.jpeg") no-repeat;
      background-size: 550px;
      background-position: top left;

      .inizio_card_header {
        font-family: muli;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      .inizio_card_subheader {
        font-family: Roboto;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
        font-weight: 500;
      }

      ul {
        padding: 0;
        list-style-type: none;

        li {
          font-family: Roboto;
          font-size: 14px;
          line-height: 18px;
          list-style: none;
          margin: 8px 0;
          counter-increment: step-counter;

          &::before {
            content: counter(step-counter) ")";
            margin-right: 8px;
            font-weight: 500;
          }
        }
      }

      .register_button {
        display: flex;
        align-items: center;
        justify-content: center;

        .full_green {
          width: 142px;
          height: 40px;
          background-color: #bed62f;
          padding: 8px 16px;
          border-radius: 8px;
          font-size: 14px;
          font-family: Roboto;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0 !important;
        }
      }
    }

    .linkPdv {
      max-width: 475px;
      margin: 0 auto;
    }
  }

  .inizio-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .inizio-footer--app-ios {
    bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  .wizardInizio {
    margin-top: 0;

    .InizioBack {
      flex: 2;
      .inizio_card {
        .register_button {
          .full_green {
            width: 100%;
            margin-top: 8px;
          }
        }
      }
    }

    .container_inizio {
      padding-top: 32px;
      padding-left: 9px;
      padding-right: 9px;
    }
  }
}

@media screen and (max-width: 420px) {
  .wizardInizio {
    .InizioBack {
      .inizio_card {
        background-size: 480px;
        background-position: top;
      }
    }
  }
}

.summary-container {
  .popup-welcome-body {
    @include medium-devices-tablet {
      padding-left: 96px;
      padding-right: 96px;

      .maskNew {
        min-height: 12rem;
      }

      .bonus-card {
        display: flex;
        gap: unset;

        .cont-description {
          display: none;
        }
      }

      #it-ar-welcomeBonus-bonusMoreInfoLink {
        display: none !important;
      }

      .mask {
        .cont-description {
          height: 3.5rem;
          line-height: 18px;
          font-size: 14px;
          -webkit-line-clamp: 3;
          width: 65%;
        }

        &.active {
          min-height: 200px;

          .element-button-secondary-activ {
            right: 19px;
            bottom: 16px;
            width: 126px;
          }

          #it-ar-welcomeBonus-bonusMoreInfoLink {
            display: inline !important;
          }
        }

        & > div {
          padding: 20px 8px 8px 47px;

          .check-mask {
            top: 20px;
            left: 15px;
          }
        }
      }

      .vedi-altri-bonus-dis {
        margin-left: unset;
        text-align: center;
      }
    }
  }

  .popup-modal .popup-promo-footer button {
    width: unset;
    min-width: 150px;
  }
}

@import "color";
@import "mixin";
.welcomeBonus {
  :not(.floatingbar).mask {
    &.tuttiigiochi {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Tutti.png");
    }
    &.scommesse {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Scommesse.png");
    }

    &.ippica {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Scommesse.png");
    }

    &.totocalcio {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Scommesse.png");
    }

    &.poker {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Lottery.png");
    }

    &.casino {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Slot_Casino.png");
    }

    &.skillgames {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Lottery.png");
    }

    &.lotterie {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Lottery.png");
    }

    &.bingo {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Slot.png");
    }

    &.quickgames {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Scommesse_GDC.png");
    }
  }

  :not(.floatingbar).maskNew {
    &.bonus {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_New.png");
    }

    &.tuttiigiochi {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Tutti.png");
    }
    &.scommesse {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Scommesse.png");
    }

    &.ippica {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Scommesse.png");
    }

    &.totocalcio {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Scommesse.png");
    }

    &.poker {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Lottery.png");
    }

    &.casino {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Slot_Casino.png");
    }

    &.skillgames {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Lottery.png");
    }

    &.lotterie {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Lottery.png");
    }

    &.bingo {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Slot.png");
    }

    &.quickgames {
      background-image: url("#{$images-path}/svg/icon/cardBonus/Master_Welcome_Bonus_Scommesse_GDC.png");
    }
  }
}

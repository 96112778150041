@import "mixin";
.opened-list {
  position: absolute;
  z-index: 2;
  width: 100%;
  max-height: 175px;
  border-radius: 0 0 0.625rem 0.625rem;
  color: $textSelectColor;
  @include sisal-box-shadow(0 0.06rem 0.188rem 0 $boxShadowGrey);
  font-weight: 300;
  cursor: default;
  font-size: 1rem;
  @include medium-devices-tablet {
    font-size: 1.63rem;
  }
  .list-group-item {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    color: #222222;
    font-weight: 400;
    font-size: 12px;
    @include ie11support {
      min-height: 3.5rem;
    }
    &:hover {
      background-color: $hoverInput;
    }
  }
}
.select-customer {
  position: relative;
  max-width: 100%;
  border-radius: 0.625rem;
  padding: 1.25rem 0.94rem;
  height: 3.75rem;
  background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat right
    0.625rem center/1.125rem 1.125rem;
  background-color: #fff;
  label {
    font-weight: 500;
    color: #3a3a3a;
    margin: 0;
    position: absolute;
    font-size: 1rem;
    padding-left: 0;
    line-height: 1.25rem;
    .flag-nation-select {
      margin-right: 0.31rem;
      @include medium-devices-tablet {
        margin-right: 0.75rem;
      }
    }
  }
  .col-prefix {
    & .list-group {
      &.opened-list {
        &.opened-list {
          top: 0;
          border-radius: 0.625rem;
          max-height: 26.25rem;
          overflow: auto;
        }
      }
    }
  }
}

.select-riepilogo,
.check-sex {
  select {
    font-size: 1rem;
    font-weight: 400;
    color: $primaryTextColor;
    width: 100%;
    height: 3.25rem;
    max-width: 42.5rem;
    background: $whiteColor;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* remove default arrow */
    background: url("#{$images-path}/svg/icon/arrow-down-black.svg") no-repeat
      #fff;
    background-position: calc(100% - 20px);
    background-size: 1.5rem;
    border-radius: 0;

    padding: 0 0.94rem;
    border: 0;
    border-bottom: 0.125rem solid $borderInput;

    @include medium-devices-tablet {
      font-size: 1.6rem;
    }
    &::-ms-expand {
      display: none; /* hide the default arrow in ie10 and ie11 */
    }
  }
  .select-customer {
    background: url("#{$images-path}/svg/icon/arrow-down-black.svg") no-repeat
      right -0.8rem center/4.375rem 1.125rem;
    @include medium-devices-tablet {
      background: url("#{$images-path}/svg/icon/arrow-down-black.svg") no-repeat
        right 0.75rem center/3.125rem 1.5rem;
    }
    background-color: #fff;
    padding: 0.625rem 0.94rem;
    height: 2.5rem;
    @include medium-devices-tablet {
      padding: 0.81rem 0.31rem;
      height: 3.75rem;
    }
  }
  .opened-list {
    position: relative;
  }
}
.flag-icon {
  min-height: 2.188rem;
  width: 100%;
}

.opened-city-list {
  width: calc(100% - 10px);
  max-height: 17.56rem;
  overflow-y: auto;
}

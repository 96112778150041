.ocr-loader-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: calc(100dvh - 50px);
  color: #222222;

  .ocr-loader-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;
    text-align: center;
  }

  .title {
    font:
      800 20px "Muli",
      sans-serif;
    margin: 0;
  }

  .description {
    font:
      14px/18px "Roboto",
      sans-serif;
    margin: 0;
  }
}

.welcomeBonus {
  .clicca-qui-per-inser {
    background-color: transparent;
    border: none;
    margin: 7px 0 11px 0;
    padding: 0;
    width: 100%;
    text-decoration: underline;
    height: 14px;
    color: #333333;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.24px;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
  }
}

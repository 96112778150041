@import "mixin";

.form-group {
  position: relative;
  // min-height: 5.2rem;
  min-height: 4rem;
  margin-bottom: 0;

  @include landscape-mob {
    margin-bottom: 0.3rem;
    &.form-group-fc {
      min-height: 3rem;
    }
    &.form-group-tabEmail {
      min-height: 3.23rem;
    }
  }

  .form-control {
    color: #3a3a3a;
    padding: 0.625rem 0.625rem 0.625rem 0.8125rem;
    display: block;
    width: 100%;
    border: 1px solid $borderInput;
    border-radius: 0.25rem;
    font-size: 1rem;
    height: 2.75rem;
    font-weight: 500;
    &:focus {
      border: 1px solid #bed62f;
      outline: none;
      @include sisal-box-shadow(none);
    }

    &:focus ~ label,
    &:valid ~ label {
      font-weight: 500;
      background-color: white;
      top: -0.6875rem;
      color: $textColor;
      font-size: 0.75rem;
    }
    &:focus ~ label {
      color: #bed62f;
    }

    &.is-invalid {
      border: 1px solid $primaryErrorColor;
      background-image: none;

      ~ label {
        top: -0.6875rem;
        color: $primaryErrorColor;
        background-color: white;
        font-weight: 500;
        font-size: 0.75rem;
      }
    }
    &.is-present {
      border-bottom: 1px solid $textAlreadyPresent;
      @include sisal-transition(0.2s ease all);
      ~ .invalid-feedback {
        color: $textAlreadyPresent;
        font-weight: 500;
        padding-left: 0.31rem;
        padding-top: 0.3rem;
        font-size: 0.75rem;
        visibility: visible;
        @include medium-devices-tablet {
          font-size: 1rem;
        }
      }
      ~ label {
        color: $textAlreadyPresent;
        font-weight: 500;
      }
    }

    /* active state */
    &:focus ~ .bar:before,
    &:focus ~ .bar:after {
      width: 100%;
      max-width: 42.5rem;
    }
  }

  .form-control-label {
    color: $textColor;
    font-weight: 300;
    position: absolute;
    pointer-events: none;
    margin-left: 0.625rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    top: 0.7rem;
    font-size: 0.875rem;
    @include sisal-transition(0.2s ease all);
    &.label-date {
      color: $textColor;
      font-size: 0.875rem;
      @include medium-devices-tablet {
        font-size: 1rem;
      }
    }
  }

  .invalid-feedback {
    color: $primaryErrorColor;
    font-weight: 500;
    padding-left: 0.31rem;
    padding-top: 0.3rem;
    font-size: 0.75rem;
    margin-top: 0;
    @include medium-devices-tablet {
      font-size: 12px;
      // padding-top: 0.5rem;
    }
  }

  .form-control {
    &:focus {
      @include sisal-box-shadow(none);
    }

    &.is-invalid {
      padding-right: 3rem;
      ~ label {
        color: $primaryErrorColor;
        font-weight: 500;
      }
      ~ .bar {
        &:before {
          height: 0.125rem;
          background: $primaryErrorColor;
          @include sisal-box-shadow(none);
        }
      }
      /* ~ .invalid-feedback {
        visibility: visible;
      }*/
    }
    &.is-present {
      border: 1px solid $textAlreadyPresent;
      @include sisal-transition(0.2s ease all);
      ~ .invalid-feedback {
        display: block;

        .invalid-alert {
          color: $textAlreadyPresent;
          font-weight: 500;
          visibility: visible;
          font-size: 0.75rem;
          display: block;
        }
      }
      ~ label {
        color: $textAlreadyPresent;
        font-weight: 500;
      }
      ~ .bar {
        &:before {
          height: 0.125rem;
          background: $textAlreadyPresent;
          @include sisal-box-shadow(none);
        }
      }
    }
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
    max-width: 42.5rem;

    &:before {
      content: "";
      height: 0.06rem;
      width: 0;
      bottom: 0.06rem;
      position: absolute;
      @include sisal-linear-double-gradient(
        90deg,
        $gradientsGreenLight,
        0%,
        $gradientsGreenDark,
        100%
      );
      @include sisal-transition(0.2s ease all);
    }
  }

  .rightEyeButton {
    position: absolute;
    top: 0.55rem;
    right: 0.94rem;

    .eyeClose {
      background-image: url("#{$images-path}/svg/icon/eye_close.svg");
      width: 1.5rem;
      background-size: 2.1rem;
      height: 1.5rem;
      background-repeat: no-repeat;
      background-position: center;
    }

    .eye {
      background-image: url("#{$images-path}/svg/icon/eye_open.svg");
      width: 1.5rem;
      background-size: 1.5rem;
      height: 1.5rem;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .upload-file-input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.form-control-label {
  color: $textColor;
  font-size: 0.75rem;
  font-weight: 300;
  @include medium-devices-tablet {
    font-size: 1rem;
  }
  &.is-invalid {
    color: #ff0000;
    font-weight: 500;
  }
  &.label-date {
    color: $textColor;
    font-weight: 300;
  }
  &.sex {
    color: #222222;
    font-weight: 400;
    font-size: 14px;
    padding-left: 0.31rem;
    line-height: 18px;
    letter-spacing: 0.28px;
    &.is-invalid {
      color: #ff0000;
      font-weight: 500;
    }
  }
  &.isSpecial {
    font-weight: 500;
    background-color: white;
    top: -9px;
    font-size: 0.75rem;
    z-index: 2;
    &.upload {
      top: -21px;
      z-index: 1;
      left: -5px;
    }
  }
  &.label-md-inline {
    @include medium-devices-tablet {
      font-size: 1.6rem;
      color: $inlineLabel;
    }
  }
}
/*.mt-second-form {
  margin-top: 4rem;
}
.invalid-feedback {
  display: block !important;
  visibility: hidden;
  position: absolute;
}*/

/* for firefox border bug */
input:required {
  box-shadow: none;
}

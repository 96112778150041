.privacy-info-modal {
  font-size: 12px;
  line-height: 14px;

  .privacy-info-modal-title {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 18px;
  }

  .privacy-info-modal-text {
    margin-bottom: 16px;
  }

  .privacy-info-modal-link {
    margin-bottom: 0px;

    .link-text,
    .mobile-link {
      padding: 0;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.domicile {
  .nation {
    .opened-nation-list {
      // top: 2.438rem;
      @include medium-devices-tablet {
        // top: 3.31rem;
      }
    }
  }
  ul.list-group.opened-list {
    overflow-y: scroll;
    width: calc(100% - 10px);
  }
  .list-group {
    background-color: white;
  }

  #it-ar-domicile-dontWorryForSerenade.bold {
    font-weight: bold;
  }

  .address-input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

div#it-ar-summary-collapse-accordion3 div#it-ar-domicile-form-group2 {
  margin-top: 0;
}

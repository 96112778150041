@import "./welcomeBonusManualInsertBtn/welcomeBonusManualInsertBtn";
@import "./welcomeBonusSeeOthers/welcomeBonusSeeOthers";
@import "./welcomeBonusCard/welcomeBonusCard";
@import "./welcomeBonusSelectedCard/welcomeBonusSelectedCard";
@import "./welcomeBonusSignUp/welcomeBonusSignUp";

.welcomeBonus {
  .maskContainer {
    margin-bottom: 8px;
  }

  #it-ar-welcomeBonus-bonusMoreInfoLink {
    all: unset;
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    display: none;
    position: absolute;
    bottom: 39px;
    left: 47px;
    cursor: pointer;

    @include medium-devices-tablet {
      display: inline;
      position: static;
    }
  }

  .bonus-card {
    display: flex;
    flex-direction: column;
    @include medium-devices-tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 10px 20px;
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .il-bonus-scelto-da-t {
    margin-left: 0 !important;
    text-align: left !important;
    left: 0px;
    color: #222222;
    font-family: Muli;
    font-size: 14px !important;
    font-weight: 800;
    letter-spacing: 0.42px;
    line-height: 20px;
  }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 950px) {
  .il-bonus-scelto-da-t {
    margin-left: 0 !important;
    text-align: left !important;
    left: 0px;
    color: #222222;
    font-family: Muli;
    font-size: 14px !important;
    font-weight: 800;
    letter-spacing: 0.42px;
    line-height: 20px;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .il-bonus-scelto-da-t {
    margin-left: 0 !important;
    text-align: left !important;
    left: 0px;
    color: #222222;
    font-family: Muli;
    font-size: 14px !important;
    font-weight: 800;
    letter-spacing: 0.42px;
    line-height: 20px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .il-bonus-scelto-da-t {
    margin-left: 0 !important;
    text-align: left !important;
    left: 0px;
    color: #222222;
    font-family: Muli;
    font-size: 14px !important;
    font-weight: 800;
    letter-spacing: 0.42px;
    line-height: 20px;
  }
}

.il-bonus-scelto-da-t {
  //margin-left: 11rem;
  color: #222222;
  font-family: Muli;
  font-size: 14px !important;
  font-weight: 800;
  letter-spacing: 0.42px;
  line-height: 20px;
}

.element-button-secondary-activ {
  position: absolute;
  right: 16px;
  bottom: 15px;
  border: 1px solid #bed62f;
  border-radius: 7px;
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 20px;
  text-align: center;
  background-color: #ffffff;
  width: 126px;
  height: 36px;
  padding-left: 0;
  padding-right: 0;
}

.ocr-page {
  .back-to-docs-btn {
    padding: 12px 24px;
    background-color: white;
    border: none;

    span {
      vertical-align: middle;
      text-decoration: underline;
      color: #222;
      font:
        bold 14px / 16px "Roboto",
        sans-serif;
    }
  }
}

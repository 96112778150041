.alert_box {
  background: #f0f5ff;
  padding: 8px;
  display: flex;
  width: 100%;
  box-shadow: inset 0px -2px 0px rgba(103, 170, 249, 0.5);
  border-radius: 5px;
  font-size: 12px;
  line-height: 16px;

  p {
    margin: 0;
  }

  .info-icon-wrapper {
    margin-right: 4px;
    display: flex;
    align-items: flex-start;
    .info-icon {
      vertical-align: unset;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & > p:not(:last-child) {
      margin-bottom: 8px;
    }

    .title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      font-family: Muli;

      &.title-warning {
        line-height: 18.75px;
        letter-spacing: -0.219px;
        font-weight: 700;
        font-family: Roboto;
      }
    }
    .text-warning-alert {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  &.warning_alert_box {
    background: #fff7e8;
    box-shadow: inset 0px -2px 0px rgba(255, 142, 13, 0.5);
  }
}

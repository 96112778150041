.contacts {
  .verify-num {
    display: flex;
    margin-top: 12px;
  }
  .verify-numNew {
    display: flex;
    margin: 15px 0 25px 0;
  }
  .success {
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #0a7d41;
    margin-top: 7px;
    @include landscape-mobile {
      width: 309px;
    }
  }
  .vector {
    position: absolute;
    right: 12px;
    top: 10px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .col-prefix {
    max-width: 13.75rem;
    .select-customer {
      color: #3a3a3a;
      padding: 0.625rem 0.625rem 0.625rem 0.8125rem;
      display: block;
      width: 100%;
      border: 1px solid $borderInput;
      background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
        right 0.2rem center/1.125rem 1.125rem;
      border-radius: 0.25rem;
      font-size: 1rem;
      height: 2.75rem;
      font-weight: 500;
      @include medium-devices-tablet {
        background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
          right 0.2rem center/1.125rem 1.125rem;
      }
    }
    .form-group {
      &.select-customer {
        padding: 0.625rem 0.625rem 0.625rem 0.8125rem;
      }
      padding-top: 0.4rem;
      min-height: auto;
      @include medium-devices-tablet {
        min-height: auto;
      }
    }

    & .list-group {
      &.opened-list {
        max-width: calc(100% - 11px);
        max-width: 92.5%;
        overflow: auto;
        top: 100%;
        z-index: 4;
        @include landscape-mobile {
          min-width: 95%;
          position: absolute;
          top: calc(100% - 0px);
        }
      }
    }
    .accordion-prefix-label {
      top: -1.25rem;
      color: $textColor;
      font-size: 0.75rem;
      @include medium-devices-tablet {
        font-size: 1rem;
      }
    }
  }
  .emailPrediction {
    position: relative;
    .gradient {
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      width: 40px;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        white
      );
      margin-right: -6px;
    }
    .predictions {
      max-width: 100%;
      overflow-x: auto;
      padding-bottom: 1rem;
      position: relative;
      scrollbar-color: transparent transparent; /* Firefox. */
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      &::-webkit-scrollbar {
        width: 0 !important;
      }

      & {
        overflow: -moz-scrollbars-none;
      }

      & {
        -ms-overflow-style: none;
      }

      white-space: nowrap;
    }
  }
  .email-btn {
    height: 35px;
    max-width: 100%;
    border-radius: 1.56rem;
    margin-right: 0.56rem;
    border: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: black;
    &.not-selected {
      background-color: #f2f2f2;
      border-radius: 1rem;
    }
    &.btn-selected {
      background: #bed62f;
    }
  }
  /*   .orangeRec{
    position: static;
    width: 5px;
    height: 32px;
    left: calc(50% - 5px/2 - 161.5px);
    top: calc(50% - 32px/2);
    background: #EBB000;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    @include landscape-mobile{
      width: 5px;
      height: 16px;
      left: calc(50% - 5px/2 - 273px);
      top: calc(50% - 16px/2);
    }
  } */
  .verifyNum {
    font-size: 12px;
    border-left: 5px solid #ebb000;
    padding-left: 15px;

    /*     left: 3.63%;
    right: 21.78%;
    top: 0%;
    bottom: 0%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 15px;
    width: 301px;
    @include landscape-mobile{
      width: 100%;
    } */
  }

  .tel {
    position: relative;
  }
}

.remove-border {
  border: 0 !important;
  background: none !important;
  max-width: 185px;
  padding-left: 0 !important;
  min-width: initial !important;
  user-select: none;
}

@import "./registrationOCRSelectionCard/registrationOCRSelectionCard";
@import "./ocrPrivacyDisclaimer/ocrPrivacyDisclaimer";
@import "./registrationOCRSelectionBottomBanner/registrationOCRSelectionBottomBanner";

.ocr-selection-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #222222;
  height: calc(100dvh - 50px - 32px); // 100dvh minus header and bottom banner
  max-width: 672px;
  margin: 0 auto;

  .ocr-selection-subcontainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .title {
    font:
      800 20px Muli,
      sans-serif;
    margin: 0;
  }

  .subtitle {
    font:
      16px/20px Roboto,
      sans-serif;
    margin: 0;
  }
}

.thank-you-page-pdv {
  .upload-document-card {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
  }

  .upload-document-card__desc {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font:
      bold 14px/18px "Roboto",
      sans-serif;
    color: #222;
  }

  .upload-document-card__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 16px;
    border: 1px solid #bed62e;
    border-radius: 8px;
    background-color: white;
    font:
      bold 14px/18px "Roboto",
      sans-serif;
    color: #222;
  }
}

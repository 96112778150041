.document-selection-container {
  .document-selection-form {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    fieldset {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }
}

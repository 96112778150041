.header-form {
  margin-bottom: 40px;

  .title-form {
    color: $formTitle;
    font-size: 0.75rem;
    font-weight: 700;

    @include medium-devices-tablet {
      font-size: 0.87rem;
    }
  }

  .sub-title-form {
    color: #222222;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 0;
    font-family: Muli;
    line-height: 24px;
  }

  .label-form-ch {
    color: $formHeaderSubTitle;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;
    letter-spacing: 0.28px;
    margin-top: 5px;
    font-family: "Muli";
    font-weight: 700;

    &.margin-bot-standard {
      margin-bottom: 1.25rem;
    }

    @include medium-devices-tablet {
      font-size: 0.87rem;

      &.margin-bot-standard {
        margin-bottom: 1.5rem;
      }
    }
  }

  .label-form {
    color: #222222;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;

    &.margin-bot-standard {
      margin-bottom: 1.25rem;
    }

    @include medium-devices-tablet {
      &.margin-bot-standard {
        margin-bottom: 1.5rem;
      }
    }

    @include landscape-mob {
      &.margin-bot-standard {
        margin-bottom: 1.3rem;
      }
    }
  }
}

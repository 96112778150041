@import "../../../sass/variables";

.summary-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title-form {
    color: $formTitle;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .sub-title-form {
    color: $textSelectColor;
    margin-bottom: 8px;
    font:
      900 24px "Muli",
      sans-serif;
  }

  .label-form {
    color: $textSelectColor;
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.input-date {
  flex-grow: 1;

  .form-control {
    display: flex;
    align-items: center;

    &.focus {
      border: 0.125rem solid #bed62f;
    }

    .input-field {
      border: 0;
      padding: 0;
      margin: 0;
      outline: 0;
      width: 1ch;
      font-size: 16px;
      line-height: 24px;

      &::placeholder {
        font-family: "Roboto Mono";
      }
    }

    input::-webkit-input-placeholder {
      color: #d9d9d9;
    }

    input:-moz-placeholder {
      color: #d9d9d9;
    }

    .slash {
      color: #d9d9d9;

      &.solid {
        color: #222;
      }
    }

    &.top-msg ~ .form-control-label {
      font-weight: 500;
      background-color: white;
      top: -0.6875rem;
      font-size: 0.75rem;
    }

    &.focus.top-msg ~ .form-control-label {
      color: #bed62f;
    }

    &.top-msg ~ .form-control-label {
      color: #757789;
    }

    &.is-invalid ~ .form-control-label {
      color: #ff3c45;
    }

    &.is-present ~ .form-control-label {
      color: #fabb00;
    }
  }
}

.is-present {
  .bar {
    &:before {
      display: none;
    }
  }
}

// div#it-ar-summary-collapse-accordion6 .inputDate .container-date .input-group.minHeight {
//   min-height: 95px;
// }

// div#it-ar-summary-collapse-accordion6 div#it-ar-ocrform-container div#it-ar-ocrform-form-group3 {
//   margin-bottom: 23px;
// }

@media only screen and (min-device-width: 300px) and (max-device-width: 767px) {
  // .inputDate .container-date.is-invalid .input-group .invalid-feedback{
  //   margin-left: 0px;
  // }
  .invalid-feedback {
    min-height: 1.5rem;
    margin-bottom: 10px;
    line-height: 12px;
    margin-top: 4px !important;
  }
  .form-group .invalid-feedback {
    line-height: 12px;
  }
  .flussoWizard div#it-ar-ocrform-form-group2 .invalid-feedback,
  .flussoWizard div#it-ar-ocrform-form-group3 .invalid-feedback {
    height: auto;
    margin-bottom: 4px;
    line-height: 12px;
    margin-top: 3px !important;
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 768px) {
  .invalid-error-date {
    padding-left: 5.9rem;
  }
}
@media screen and (max-width: 360px) {
  .invalid-error-date {
    padding-left: 0;
  }
}

@media screen and (max-width: 280px) {
  .invalid-error-date {
    padding-left: 0;
  }
}
.invalid-alert-date {
  color: #fabb00;
  font-weight: 500;
  display: block;
  padding-left: 0.31rem;
  visibility: visible;
  font-size: 12px;
}
@media screen and (width: 375px) {
  .invalid-alert-date {
    padding-left: 5.8rem !important;
  }
}

@media screen and (max-width: 767px) {
  div#it-ar-summary-container
    div#it-ar-summary-collapse-accordion2
    .inputDate
    .container-date
    .input-group.minHeight {
    min-height: 80px;
  }
}

.input-group-prepend.summary.birthDate {
  input {
    padding: 0;
  }
}

.bonus-description-modal {
  font-size: 14px;
  line-height: 18px;
  color: #222;

  b {
    font-weight: 700;
  }

  a {
    font-family: Roboto, sans-serif;
    pointer-events: none;
    cursor: default;
    color: #222;
  }

  ul {
    padding-left: 16px;
  }

  p {
    margin-bottom: 0;
  }

  .bonus-pointed-rules {
    max-height: calc(90vh - 124px);
    padding: 16px;
    overflow: auto;
  }

  .modal-body-product-title {
    color: var(--neutral-black, #222);
    font:
      bold 12px Muli,
      sans-serif;
    text-transform: uppercase;
    line-height: 12px;
  }

  .modal-body-title-how-it-works {
    font:
      bold 16px Muli,
      sans-serif;
    line-height: 12px;
    margin-bottom: 8px;
  }

  .modal-body-how-it-works {
    margin-bottom: 8px;
  }

  .modal-body-heading {
    font:
      800 18px Muli,
      sans-serif;
    margin-bottom: 8px;
  }

  .modal-body-what-bonus {
    font:
      14px Roboto,
      sans-serif;
    line-height: 18px;
    margin-bottom: 24px;
  }

  .modal-body-list {
    font:
      14px Roboto,
      sans-serif;
    line-height: 18px;
  }

  .modal-footer-info {
    font:
      400 12px Roboto,
      sans-serif;
    padding-left: 0.3rem;
    border-left: 2px solid orange;
  }
}

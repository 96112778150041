.birthplace {
  .row {
    .nation {
      .opened-nation-list {
        // top: 3rem;
        @include medium-devices-tablet {
          // top: 3.31rem;
        }
      }
    }
  }
  .invalid-alert {
    position: relative;
  }
}

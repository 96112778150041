.divFlexSuggestPassword {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border: 3px solid #f4f4f5;
  border-radius: 12px;
  background-color: #ffffff;
  flex-wrap: wrap;
  margin-top: 12px;
  ul {
    color: #222222;
    font-family: Muli;
    margin-top: 13px;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    font-weight: bold;
    padding-left: 17px;
  }
  ul li {
    font-family: Roboto;
    font-size: 12px;
    line-height: 12px;
    color: #222222;
    font-weight: 400;
    margin-top: 12px;
    margin-right: 18px;
    margin-left: 16.5px;
  }
}

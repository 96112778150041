.summary-container {
  .popup-address-body {
    .header-form {
      display: none;
    }

    .residence,
    .domicile {
      padding: 0;
    }

    #it-ar-residence-form-group6 {
      min-height: unset;
    }
  }
}

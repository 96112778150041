@import "mixin";
@import "color";

$landscape-mobile: 576px;
$landscape-iphone: 415px;
$landscape-smallMobile: 355px;
$landscape-extraSmall: 342px;
$landscape-mob: 600px;
$landscape-android: 360px;
$medium-devices-tablet: 768px;
$desktop: 992px;
$big-desktop: 1200px;
$long-smartphone-height: 576px;

/* Small devices (landscape phones, 576px and up) */
@mixin landscape-mobile {
  @media (min-width: $landscape-mobile) {
    @content;
  }
}

@mixin landscape-extraSmall {
  @media (max-width: $landscape-extraSmall) {
    @content;
  }
}

@mixin landscape-smallMobile {
  @media (max-width: $landscape-smallMobile) {
    @content;
  }
}

@mixin landscape-mob {
  @media (max-width: $landscape-mob) {
    @content;
  }
}

@mixin landscape-iphone {
  @media (max-width: $landscape-iphone) {
    @content;
  }
}
@mixin landscape-android {
  @media (max-width: $landscape-android) {
    @content;
  }
}
/* Medium devices (tablets, 768px and up) */
@mixin medium-devices-tablet {
  @media (min-width: $medium-devices-tablet) {
    @content;
  }
}
/* Large devices (desktops, 992px and up) */
@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@mixin big-desktop {
  @media (min-width: $big-desktop) {
    @content;
  }
}

/* Long smartphone like iphoneX (576px of height and up) */
@mixin long-smartphone {
  @media (min-height: $long-smartphone-height) {
    @content;
  }
}

/* support for internet explorer 11*/
@mixin ie11support {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

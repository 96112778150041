@import "./partials/townLiContent";

.town {
  ul.list-group.opened-list {
    overflow-y: scroll;
    width: 100%;
  }
  .opened-list {
    max-height: 310px;
    cursor: pointer;
    @media (max-width: 768px) {
      max-height: 175px;
    }
  }
  .list-group {
    background-color: white;
    li.list-group-item {
      border-color: transparent;
      &:hover {
        background-color: #f2f7d5;
      }
    }
  }
  .form-control {
    &.notExistCity {
      background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
        right 0.55rem center/18px white;
    }
    &.existCity {
      background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
        right 0.55rem center/18px white;
    }
    &::placeholder {
      font: 500 16px "Roboto";
      color: transparent;
    }
    &:focus::placeholder {
      color: #d4d5d5;
    }
  }
  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
  }
}

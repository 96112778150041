#typ-ccm-email-step {
  .popup-modal-body {
    .modal-body-title {
      margin-bottom: 16px;
      border: none;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      font-family: Muli;
    }

    .modal-body-text {
      margin-bottom: 16px;
    }

    .sub-note {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

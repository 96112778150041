.error-card-ocr-page {
  .error-card {
    display: flex;
    flex-direction: column;
    margin: 0 auto 16px;
    row-gap: 24px;
    max-width: 550px;
    padding: 24px;
    box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    background-color: white;
    color: #222;

    @include medium-devices-tablet {
      width: 550px;
    }
  }

  .error-card__img {
    margin: 0 auto;
  }

  .error-card__text {
    display: flex;
    flex-direction: column;
  }

  .error-card__title {
    color: #222;
    font:
      800 20px / 20px "Muli",
      sans-serif;
    margin-bottom: 12px;
  }

  .error-card__description {
    color: #222;
    font:
      14px / 18px "Roboto",
      sans-serif;
    margin-bottom: 0;
  }

  .error-card__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin-right: auto;

    @include medium-devices-tablet {
      flex-direction: row-reverse;
      width: unset;
      justify-self: flex-start;
    }
  }

  .error-card__btn-primary {
    font:
      bold 14px / 18px "Roboto",
      sans-serif;
    background-color: #bed62e;
    border: 1px solid #2222221f;
    border-radius: 8px;
    padding: 8px 16px;
    min-width: 150px;
  }

  .error-card__btn-secondary {
    background-color: white;
    border: 1px solid #bed62e;
    border-radius: 8px;
    font:
      bold 14px / 18px "Roboto",
      sans-serif;
    padding: 8px 16px;
  }

  .error-card__note {
    border-left: 2px solid #ffbf00;
    font:
      400 12px / 14px "Roboto",
      sans-serif;
    padding-left: 8px;
    margin-bottom: 0;
    width: 100%;

    ul {
      padding-left: 24px;
    }
  }
}

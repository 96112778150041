.termsAndConditions {
  .header-form {
    margin-bottom: 32px;
  }

  .invalid {
    border: 0.125rem solid #ff3c45;
    background: none;
  }

  .color-subtext-checkbox {
    color: #222222;
    font-family: Roboto;
    font-size: 0.87rem;
    font-weight: 300;
    letter-spacing: 0.28px;
    line-height: 18px;
  }

  .color-text-checkbox {
    color: $primaryTextColor;
    font-family: Roboto;
    font-weight: normal;
    font-size: 0.87rem;
  }

  .form-group-checkbox {
    line-height: 1rem;

    @include medium-devices-tablet {
      line-height: inherit;
    }
  }

  .documentViewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: $primaryBgColor;
    overflow: hidden;

    .closePdf {
      background-color: $pdfHeaderBackgroundColor;
      position: absolute;
      top: 0;
      width: 100%;
      height: 3.5rem;
      z-index: 5;
      text-align: right;

      @include medium-devices-tablet {
        height: 5rem;
      }

      .btn-discard {
        width: 1.5rem;
        height: 1.5rem;
        margin: 1rem;
        padding: 0;
        background: url("#{$images-path}/svg/icon/close-upfile-white.svg")
          no-repeat;
        background-size: 1.5rem 1.5rem;
        border: none;

        @include medium-devices-tablet {
          margin: 1.75rem;
          width: 1.5rem;
          height: 1.5rem;
          background-size: 1.5rem 1.5rem;
        }
      }
    }

    .react-pdf__Document {
      height: calc(100vh - 7rem);
      width: 100%;
      position: absolute;
      top: 3.5rem;
      overflow: auto;
      overflow-x: hidden;

      @include medium-devices-tablet {
        top: 5rem;
        height: calc(100vh - 10rem);
      }

      .react-pdf__Page__canvas {
        width: 100vw !important;
        height: calc(
          100vw * (842 / 595)
        ) !important; //per mantenere le proporzioni (595/842 = aspect ratio originale)
      }
    }
  }

  input:checked ~ .checkmark {
    background: url("#{$images-path}/svg/termsAndCondition/checkboxCheck.svg")
      no-repeat;
  }

  #it-ar-termsandconditions-form-check4,
  #it-ar-termsandconditions-form-check5 {
    padding: 0;

    & > .form-check-label {
      padding: 0;
    }
  }

  .radio-container {
    display: flex;
    margin-bottom: 8px;

    .radio-box {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 48px;
      }

      label {
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        color: #222222;
      }

      .terms-radio-button {
        margin-right: 8px;
        appearance: none;
        background-color: #fff;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid $borderInput;
        cursor: pointer;

        &:checked {
          background: url("#{$images-path}/svg/termsAndCondition/radioCheck.svg")
            no-repeat;
          background-position: center;
        }
      }
    }
  }

  .tripartion-container {
    width: fit-content;
    margin: 12px 0 8px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #fabb00;
    font-size: 12px;
    line-height: 15.6px;
    color: #222222;

    @media screen and (min-width: 768px) {
      padding: 8px 16px;
    }

    label {
      margin: 0;
      // color: #222222;

      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }

    .tripartion-text {
      margin: 0;
      font-size: 12px;
      line-height: 18px;

      &.tripartion-alert {
        margin-bottom: 8px;

        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }

      b {
        font-weight: bold;
      }
    }

    .tripartion-action-area {
      display: flex;

      .checkbox-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 32px;
        }

        input:checked ~ .tripartion-chechmark {
          border: 0;
          background: url("#{$images-path}/svg/termsAndCondition/checkboxCheck.svg")
            no-repeat;
          background-size: cover;
        }

        .tripartion-chechmark {
          margin-right: 4px;
          background: none;
          border: 1px solid $borderInput;
          box-sizing: border-box;
          border-radius: 4px;
          width: 20px;
          height: 20px;
          top: 0;

          .term {
            &.aligned-tripartion-checkmark {
              top: 0 !important;
            }
          }
        }

        .tripartion-chechmark {
          &.term {
            background: none;
            border: 1px solid $borderInput;
            box-sizing: border-box;
            border-radius: 4px;
            width: 24px;
            height: 24px;
            top: 0 !important;
          }
        }
      }
    }
  }

  .re-captcha {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #474a61;
    font-family: Roboto;
    font-size: 10px;
    margin-bottom: 0.375rem;
    line-height: 12px;
    label {
      margin: 0;
      a {
        color: #b9d137;
      }
    }
  }

  .terms-sign-button {
    @media screen and (max-width: 576px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include desktop {
      margin: auto;
      max-width: 500px;
    }

    #btn_indietro_container {
      flex-grow: 1;

      #btn_indietro {
        margin-right: 16px;
      }

      .btnLabelback {
        display: none;
        @include medium-devices-tablet {
          display: inline;
        }
      }
    }
  }

  .mobile-link {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    padding-top: 5px;
    display: inline-block;

    @media screen and (min-width: 768px) {
      font-size: 0.87rem;
    }
  }

  #it-ar-termsandconditions-form-group-checkbox3 {
    margin-bottom: 24px;
  }

  #it-ar-termsandconditions-form-group-checkbox5 {
    margin-bottom: 0;
  }
}

.flussoWizard .termsAndConditions .color-subtext-checkbox {
  font-weight: 400;
}

.flussoWizard .termACD .form-group-checkbox {
  @include medium-devices-tablet {
    margin-bottom: 22px;
  }
}

.flussoWizard .termACD div#it-ar-termsandconditions-form-check5 {
  @include medium-devices-tablet {
    margin-top: -7px !important;
  }
}

.link-bold {
  color: #222222;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 24px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #bed62f;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  height: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.switchButt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.selezionelbl {
  margin-left: 10px;
}

.wizard--B,
.wizard--R,
.wizard--A {
  .pdf-link-B {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    @media screen and (min-width: 768px) {
      font-size: 0.87rem;
    }

    span {
      b {
        u {
          font-weight: bold;
        }
      }
    }
  }

  .terms-box {
    display: flex;
  }

  .termsAndConditions {
    .form-group-checkbox {
      margin-bottom: 16px;
      margin-top: 0 !important;
    }
  }

  .form-check {
    &#it-ar-termsandconditions-form-check,
    &#it-ar-termsandconditions-form-check2,
    &#it-ar-termsandconditions-form-check3 {
      display: flex;
      align-items: center;
      height: 24px;
    }

    .link-bold {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: normal;

      @media screen and (min-width: 768px) {
        font-size: 0.87rem;
      }

      &.other-link {
        font-weight: normal;

        u {
          font-weight: bold;
        }
      }
    }

    input:checked ~ .checkmark {
      border: 0;
    }

    .checkmark {
      background: none;
      border: 1px solid $borderInput;
      box-sizing: border-box;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      top: 0;
      margin-top: 0;

      .term {
        &.aligned-checkmark {
          top: 0 !important;
        }
      }
    }

    .terms-new {
      position: relative;

      a {
        font-weight: normal;

        b u {
          font-weight: bold;
        }
      }

      .terms-new-text {
        b {
          font-weight: 700;
        }
      }

      .terms-new-text,
      .terms-new-accordion-text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #222222;
        margin-bottom: 8px;

        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }

      & > p {
        margin-right: 35px;
      }

      &.without-arrow {
        &::after {
          display: none;
        }
      }

      .info-privacy-link {
        text-decoration: underline;
        font-weight: 700;
        cursor: pointer;
      }
    }

    .border-bottom {
      border-bottom: 1px solid $borderInput;
      width: 100%;
    }
  }

  .checkmark {
    &.term {
      background: none;
      border: 1px solid $borderInput;
      box-sizing: border-box;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      top: 0 !important;
    }
  }

  .information-text {
    color: #191d3a;
    font-family: Roboto;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;

    b {
      font-weight: 700;
    }

    @media screen and (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

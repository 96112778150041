.errorCardsPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .cardsContainer {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 432px;
    width: 500px;
    width: 100%;
    margin-top: 16px;
    padding: 24px 16px;

    & > *:not(:last-child) {
      margin-bottom: 36px;
    }

    @include medium-devices-tablet {
      margin-top: 36px;
    }
  }

  #preFooter {
    margin-top: 0;

    .contacts-options-container {
      margin-top: 36px;
    }

    @include medium-devices-tablet {
      margin-top: 66px;

      .contacts-options-container {
        margin-top: 0px;
      }
    }
  }
}

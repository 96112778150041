@import "./promoCodePopup/promoCodePopup";

.promo-input-valid {
  .btn-verify {
    padding-left: 0.81rem;
    font-weight: bold;
    color: #222222;
    img {
      width: 1.5rem;
    }
  }
}

.promo-input-text {
  padding-left: 0.31rem;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  @include medium-devices-tablet {
    font-size: 1rem;
  }
}

.promo-input-error {
  border: 0.06rem solid red !important;
  .promo-input-text {
    padding: 0;
    color: $inputTextError;
    font-weight: 500;
  }
}

.promo-input-valid {
  .promo-input-text {
    padding: 0;
    color: $primaryTextColor;
    font-weight: 500;
  }
}

.promo-input-container {
  //width: 100%;
}

.error-msg {
  height: 18px;
  width: 266px;
  color: #ff3c45;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
}
.setCoursor {
  cursor: auto !important;
}

.getCoursor {
  cursor: pointer;
}
.floatingbar-container {
  .promo-input-container {
    position: relative;
    .form-group {
      min-height: unset;
    }
    .form-control-label {
      left: 0;
    }
    @include medium-devices-tablet {
      margin-left: auto;
      display: inline-block;
      width: 20.31rem;
      padding: 0;
    }

    .promo-input {
      height: 2.75rem;
      border-radius: 4px;
      width: 100%;
      border-width: 0.125rem;
      padding-right: 3rem;
      padding-left: 1.5rem;
      box-shadow: none;
      &::placeholder {
        font-weight: 300;
      }
      &:focus {
        border-color: #ced4da;
      }
      &::-ms-clear {
        display: none;
      }
    }

    .promo-input-text {
      margin-top: 0.625rem;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;
      display: none;
      text-align: justify;
    }

    .btn-clear {
      position: absolute;
      right: 1.25rem;
      top: 0.8rem;
      cursor: pointer;
      width: 0.8rem;
      height: 1.5rem;
      background-image: url("#{$images-path}/svg/icon/close-upfile-dark.svg");
      background-repeat: no-repeat;
      background-size: 1rem 1.5rem;
    }
  }

  .btn-verify {
    font-weight: bold;
    color: #222222;
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: none;
    margin-top: 1.56rem;
    width: 100%;
    @include medium-devices-tablet {
      margin-left: 1.375rem;
      max-width: 10rem;
    }
  }

  &.active {
    .promo-input-error {
      .promo-input-container {
        padding-bottom: 0;

        .promo-input {
          border: 0.125rem solid $inputTextError;
        }
        .form-control-label {
          color: $inputTextError;
        }

        .promo-input-text {
          display: block;
        }
      }
    }

    .promo-input-valid {
      .promo-input-container {
        padding-bottom: 0;

        .promo-input {
          border: 0.125rem solid #bed62f;
        }

        .form-control-label {
          color: #bed62f;
        }
        .promo-input-text {
          display: block;
        }
      }
    }
  }
}
.promo-input-error {
  .promo-input-container {
    padding-bottom: 0;

    .promo-input {
      border: 0.06rem solid $inputTextError;
      background-color: $errorInputBg;
    }

    .promo-input-text {
      display: block;
    }
  }
}

.promo-input-valid {
  .promo-input-container {
    padding-bottom: 0;

    .promo-input {
      border: 0.18rem solid #bed62f;
      background-color: $validInputBg;
    }

    .promo-input-text {
      display: block;
    }
  }
}

.label-error {
  color: var(--danger);
}

.input-error {
  border-color: var(--danger);
}

.summary-promo-code {
  max-width: 100%;
}

.summary-promo-code .form-group {
  min-height: 3.7rem;
  width: 65%;
  margin-right: 7px;
}

.summary-second-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.mobile-notification {
  display: none;
}

.main {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gift {
  height: 67px;
  width: 67px;
  min-height: 67px;
  margin-top: 4.5625rem;
  min-height: 67px;
}

.description {
  margin-top: 1.5rem;
}

.inserisci-qui-il-cod {
  color: #000000;
  font-family: "Muli";
  font-size: 24px;
  letter-spacing: 0.48px;
  line-height: 28px;
  text-align: center;
  font-weight: 900;
}

.potrai-riscattare-il {
  margin-top: 1rem;
  color: #222222;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.24px;
  line-height: 14px;
  text-align: center;
  width: 386px;
}

.inserisci-codice {
  width: 306px;
  height: 2rem;
  border-radius: 4px !important;
  border: 2px solid #d1d2d8;
  padding: 10px 42px 10px 13px !important;
  font-size: 14px !important;
  font-family: Roboto !important;
}

.label-left {
  left: 0;
}

.input-clear {
  position: absolute;
  right: 4.05px;
  border: none;
  background: none;
  top: 22px;
  margin-top: -13px;
  color: #212529;
}

.input-clear2 {
  position: absolute;
  right: 4.05px;
  border: none;
  background: none;
  top: 22px;
  margin-top: -13px;
  color: #212529;
}

.relative {
  position: relative;
  display: inline-block;
}

.verifica {
  margin-top: 24px;
}

.label-error {
  color: var(--danger);
}

.grazie-per-aver-scel {
  width: 352px;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 20px;
  height: 56px;
  // width: 352px;
  color: #000000;
  font-family: Muli;
  font-size: 23px;
  letter-spacing: 0.48px;
  line-height: 28px;
  text-align: center;
  font-weight: 900;
}

.valid-bonus-code-text {
  margin-top: 16px;
  margin-bottom: 0;
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.component-button-primary-activ {
  height: 40px;
  width: 290px;
  background-color: #bed62f;
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 18px;
  text-align: center;
  border: none;
  border-radius: 7px;
  display: block !important;
}

.component-button-primary-disabled {
  height: 40px;
  width: 290px;
  background-color: #e2f284;
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 18px;
  text-align: center;
  border: none;
  border-radius: 7px;
  display: block !important;
}

.component-button-primary-activ-new-disabled {
  display: flex;
  align-items: center;
  background-color: #90cd418c;
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 18px;
  margin-top: 1rem;
  border: none;
  padding: 11px 10px 11px 27.23px;
  border-radius: 7px;
}

.component-button-primary-activ-new {
  display: flex;
  align-items: center;
  background-color: #bed62f;
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 18px;
  margin-top: 1rem;
  border: none;
  padding: 11px 10px 11px 27.23px;
  border-radius: 7px;
}

.icon-close {
  position: absolute;
  height: 16px;
  width: 16;
  top: 48px;
  right: 141px;
  font-size: 40px;
  color: #222222;
  cursor: pointer;
}

.potrai-margin {
  margin-bottom: 22px !important;
}

.popup {
  margin: 0 !important;
  position: relative;
  width: 100% !important;
  min-height: 100vh;
  border-radius: 10px !important;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 #c8c8c8;
  padding-bottom: 35%;
}

@media only screen and (min-device-width: 400px) and (max-device-width: 950px) {
  .potrai-margin {
    margin-bottom: 12px !important;
  }
  .icon-close {
    position: absolute;
    height: 0.45rem !important;
    width: 0.45rem !important;
    top: 31px !important;
    right: 28px !important;
    font-size: 30px !important;
    color: #222222;
    cursor: pointer;
  }

  .verifica {
    margin-top: 15px;
  }

  .setCoursor {
    cursor: auto !important;
  }

  .main {
    height: calc(100% - 300px);
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 640px) {
  .potrai-margin {
    margin-bottom: 12px !important;
  }
  .ricevi-15-€-al-docume {
    max-width: 306px;
    color: #000000;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    margin-top: 1rem;
  }
  .icon-close {
    position: absolute;
    height: 0.45rem !important;
    width: 0.45rem !important;
    top: 31px !important;
    right: 28px !important;
    font-size: 30px !important;
    color: #222222;
    cursor: pointer;
  }

  .description {
    margin-top: 1.5rem;
    max-width: 306px !important;
  }
  .setCoursor {
    cursor: auto !important;
  }

  .inserisci-qui-il-cod {
    width: 306px;
    color: #000000;
    font-family: "Muli";
    font-size: 24px;
    letter-spacing: 0.48px;
    line-height: 28px;
    text-align: center;
    max-width: 17.5rem !important;
    font-weight: 900;
  }

  .potrai-riscattare-il {
    width: 322px;
    margin-top: 1.2rem;
    color: #222222;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.24px;
    line-height: 14px;
    text-align: center;
    max-width: 17.5rem !important;
    margin: auto;
    padding-bottom: 1rem;
  }

  .input-clear {
    position: absolute;
    right: 4.05px;
    border: none;
    background: none;
    top: 22px;
    margin-top: -13px;
  }

  .verifica {
    margin-top: 15px;
  }
  .main {
    // height: calc(100% - 150px);
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .potrai-margin {
    margin-bottom: 12px !important;
  }
  .main {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 300px);
  }

  .promo-input-text {
    padding-left: 0.31rem;
    padding-top: 0.5rem;
    font-size: 0.75rem;
    @include medium-devices-tablet {
      font-size: 1rem;
    }
  }

  .promo-input-error {
    border: 0.06rem solid red !important;
    .promo-input-text {
      padding: 0;
      color: $inputTextError;
      font-weight: 500;
    }
  }

  .promo-input-valid {
    .promo-input-text {
      padding: 0;
      color: $primaryTextColor;
      font-weight: 500;
    }
  }

  .gift {
    height: 67px;
    width: 67px;
    min-height: 67px;
    margin-top: 4.5625rem;
  }

  .description {
    margin-top: 1.5rem;
    max-width: 306px;
  }

  .inserisci-qui-il-cod {
    width: 306px;
    color: #000000;
    font-family: "Muli";
    font-size: 24px;
    letter-spacing: 0.48px;
    line-height: 28px;
    text-align: center;
    max-width: 17.5rem !important;
    font-weight: 900;
  }

  .potrai-riscattare-il {
    width: 322px;
    margin-top: 1.2rem;
    color: #222222;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0.24px;
    line-height: 14px;
    text-align: center;
    max-width: 17.5rem !important;
    margin: auto;
    padding-bottom: 1rem;
  }

  .inserisci-codice {
    padding-left: 0.5rem !important;
    border-radius: 4px !important;
    //border: 2px solid #D1D2D8!important;
    padding-right: 2rem !important;
  }

  .input-clear {
    position: absolute;
    right: 4.05px;
    border: none;
    background: none;
    top: 22px;
    margin-top: -13px;
  }

  .relative {
    position: relative;
  }

  .grazie-per-aver-scel {
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 20px;
    height: 56px;
    max-width: 306px;
    color: #000000;
    font-family: Muli;
    font-size: 24px;
    letter-spacing: 0.48px;
    line-height: 28px;
    text-align: center;
    font-weight: 900;
  }

  .promo-input-error .promo-input-text {
    padding: 0;
    color: #ff0000;
    font-weight: 500;
    max-width: 19rem !important;
  }
  .verifica {
    margin-top: 15px;
  }

  .grazie-per-aver-scel {
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 20px;
    height: 56px;
    max-width: 19rem !important;
    color: #000000;
    font-family: Muli;
    font-size: 23px;
    letter-spacing: 0.48px;
    line-height: 28px;
    text-align: center;
    font-weight: 900;
  }

  .ricevi-15-€-al-docume {
    max-width: 306px;
    color: #000000;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    margin-top: 1rem;
  }

  .component-button-primary-activ {
    height: 40px;
    width: 290px;
    background-color: #bed62f;
    color: #222222;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.28px;
    line-height: 18px;
    text-align: center;
    border: none;
    border-radius: 7px;
    display: block !important;
  }

  .component-button-primary-activ-new {
    display: flex;
    align-items: center;
    background-color: #bed62f;
    color: #222222;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.28px;
    line-height: 18px;
    margin-top: 1rem;
    border: none;
    padding: 11px 10px 11px 27.23px;
    border-radius: 7px;
  }

  .icon-close {
    position: absolute;
    height: 0.45rem !important;
    width: 0.45rem !important;
    top: 31px !important;
    right: 28px !important;
    font-size: 30px !important;
    color: #222222;
    cursor: pointer;
  }

  .element-field-close-icon {
    height: 24px;
    width: 23px;
  }

  .setCoursor {
    cursor: auto !important;
  }

  .popup {
    margin: 0 !important;
    height: 100% !important;
    width: 100% !important;
    border-radius: 10px !important;
    background-color: #ffffff;
    box-shadow: 0 2px 20px 0 #c8c8c8;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    overflow: hidden;
  }
  .main {
    height: calc(100% - 300px);
  }
}

@media screen and (max-width: 640px) {
  .potrai-margin {
    margin-bottom: 12px !important;
  }
  .mobile-notification {
    display: block;
    margin-bottom: 10px;
  }

  .summary-second-group {
    display: flex;
    flex-direction: column;
  }

  .desktop-notification {
    display: none !important;
  }

  .summary-promo-code .form-group {
    width: 100%;
    margin-right: 0;
  }
  .input-clear {
    position: absolute;
    right: 4.05px;
    border: none;
    background: none;
    top: 22px;
    margin-top: -13px;
  }

  .input-clear2 {
    position: absolute;
    right: 4.05px;
    border: none;
    background: none;
    top: 22px;
    margin-top: -13px;
  }
  .grazie-per-aver-scel {
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 20px;
    height: 56px;
    max-width: 306px;
    color: #000000;
    font-family: Muli;
    font-size: 24px;
    letter-spacing: 0.48px;
    line-height: 28px;
    text-align: center;
    font-weight: 900;
  }

  .ricevi-15-€-al-docume {
    max-width: 306px;
    color: #000000;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    margin-top: 1rem;
  }

  .verifica {
    margin-top: 15px;
  }
}
.centrelize-input {
  max-width: 360px;
  margin: 0 auto;
}

.centrelize-input {
  max-width: 360px;
  margin: 0 auto;
}

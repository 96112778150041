.summary-container {
  .popup-info-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .popup-info-block-title {
    color: #929596;
    font: 500 12px Roboto;
    line-height: 14px;
  }

  .popup-info-block-data {
    color: #262c2f;
    font: 400 16px Roboto;
    line-height: 20px;
  }
}

#typ-ccm-final-step {
  .popup-modal-body {
    padding-bottom: 24px;

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 16px;
    }

    .modal-text {
      margin: 0;
      font-size: 16px;
      line-height: 130%;
      color: #030303;
    }
  }
}

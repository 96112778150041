header {
  height: 50px;
  border-bottom: 1px solid $stepBorder;
  display: flex;
  align-items: center;
  .verificationEmailHeaderLogo {
    height: 31px;
    display: inline-block;
    padding-left: 31px;
    img {
      height: 100%;
    }
  }
}
.conferma-email {
  max-width: 912px;
  width: 100%;
  padding-top: 74px;
  display: flex;
  align-items: center;
  .vai-homepage {
    color: #222222;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.28px;
    line-height: 18px;
    text-align: center;
    padding: 9px 16.74px;
    background-color: #bed62f;
    border: 0;
    box-shadow: none;
    border-radius: 7px;
  }
  h1 {
    color: #222222;
    font-family: "Muli";
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0.48px;
    line-height: 28px;
    margin-bottom: 23px;
  }
  .conferma-text {
    margin-bottom: 37px;
  }
  p {
    color: #000000;
    font-family: "Roboto";
    font-size: 14px;
    letter-spacing: 0.28px;
    line-height: 18px;
  }
  .preFooter {
    margin: 0;
    > div {
      justify-content: initial !important;
      flex-direction: row !important;
      .popUpCol {
        padding-left: 0;
        padding-right: 37px;
        display: block;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .conferma-email {
    img {
      width: 95%;
    }
  }
}
@media screen and (max-width: 768px) {
  .conferma-email {
    max-width: 100%;
    margin: 0;
    padding-top: 25px;
    img {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

#confirm-code-modal {
  .overlay.popup-modal-overlay {
    .popup-modal {
      .popup-modal-body {
        padding-bottom: 10px;

        h6 {
          font-family: Roboto, sans-serif;
          font-weight: 700;
          font-size: 16px;
          color: #030303;
          line-height: 20px;
        }

        p {
          font-family: Roboto, sans-serif;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 0;
        }

        .popupmodal-img {
          display: flex;
          justify-content: center;
          height: fit-content;
          margin: 10px 0;

          img {
            align-items: center;
          }
        }

        .info-codice-loyalty {
          margin-top: 3px;
          color: #44546a;
          font-family: Robot, sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 0px;
        }

        .loyalty-error {
          color: #e32219;
        }
      }
      .popup-modal-footer {
        #close-loyalty-btn {
          background-color: #ffffff;
          border: 1px solid #bed62f;

          @media screen and (min-width: 601px) {
            width: 150px;
          }
        }

        #insert-loyalty-btn {
          border: 1px solid #bed62f;
          background-color: #bed62f;
          max-height: 42px;
          font-weight: 700;
          @media screen and (min-width: 601px) {
            width: 150px;
          }
        }

        #insert-loyalty-btn:disabled {
          background-color: #def199;
        }

        #close-success-loyalty-btn {
          background-color: #bed62f;
        }
      }
    }
  }
}

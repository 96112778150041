.preFooter {
  &.preFooter-popup-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 3;
  }

  .preFooter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 0;
    height: 56px;
    color: white;
    background-color: #2f3c43;
    font: 800 20px "Muli";
    border-radius: 10px 10px 0 0;
  }

  .btn-discard {
    height: 24px;
    width: 24px;
    background: #2f3c43 url("#{$images-path}/svg/icon/close-upfile-white.svg")
      no-repeat right;
    border: none;
  }

  .preFooter-popup-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 24px;
    background-color: white;
    height: 100%;
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(34, 34, 34, 0.5);
  top: 0;
  left: 0;
  z-index: 2;
}

.summary-container {
  .popup-recharge-limits-body {
    .header-form {
      display: none;
    }

    .recharge-limit {
      padding: 0;
    }

    div.recharge-limit {
      max-width: unset !important;
    }

    .recharge-limits-summary-title {
      font-size: 12px;
    }

    .recharge-limit-sub-note {
      min-height: unset;
      margin-bottom: 0;
    }

    .sub-note {
      margin-bottom: 0;
    }

    #it-ar-rechargelimit-row1 {
      margin-bottom: 0;
    }
  }

  .recharge-limit-evaluation-modal {
    .popup-modal-footer {
      button {
        @include medium-devices-tablet {
          width: unset;
        }
      }
    }
  }
}

.ocrForm {
  .tooltip-container {
    display: flex;
    position: absolute;
    font-family: "Roboto";
    font-style: normal;
    flex-direction: column;
    align-items: flex-start;
    z-index: 10;
    right: -235px;
    background: #44546a;
    box-shadow: 4px 8px 16px rgba(161, 161, 161, 0.25);
    border-radius: 7px;
    color: white;
    top: 44px;
    padding: 15px;
    width: calc(100% + 15px);
    left: 50%;
    transform: translateX(-50%);

    @include medium-devices-tablet {
      top: -85px;
      left: unset;
      padding: 16px;
      width: 223px;
      transform: unset;
    }

    .img-triangle {
      position: absolute;
      top: -15px;
      right: 23px;
      transform: rotate(90deg);

      @include medium-devices-tablet {
        top: 95px;
        left: -10px;
        right: unset;
        transform: unset;
      }
    }

    .img-close {
      display: block;
      position: absolute;
      width: 22px;
      height: 22px;
      right: 17px;
      top: 15px;

      @include medium-devices-tablet {
        display: none;
      }
    }

    .img-documentId-type {
      position: absolute;
      margin: auto;
      bottom: 8px;
      border-radius: 10px;
      right: 16px;
      left: auto;

      @include medium-devices-tablet {
        left: 0;
        right: 0;
        border-radius: unset;
      }
    }

    .img-id-card {
      width: 191px;
      height: 120px;
      top: 40px;
      background: url("../../../images/png/id-card.png") no-repeat center
        transparent;
    }

    .img-driver-license {
      width: 191px;
      height: 122px;
      background: url("../../../images/png/driverLicense.png") no-repeat center
        transparent;
    }

    .img-passport {
      width: 190px;
      height: 140px;
      background: url("../../../images/png/passport.png") no-repeat center
        transparent;
      border-radius: 2px 2px 10px 10px;
    }

    .tooltip-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }

    .tooltip-info {
      margin-top: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      width: 35%;

      @include medium-devices-tablet {
        width: unset;
      }
    }

    .bottom-desc {
      display: flex;
      position: absolute;
      justify-content: flex-end;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      bottom: 20px;
      width: 35%;

      @include medium-devices-tablet {
        width: unset;
      }
    }
  }

  .tooltip-size-idCard {
    height: 170px;

    @include medium-devices-tablet {
      height: 260px;
    }
  }

  .tooltip-size-driver-license {
    height: 170px;

    @include medium-devices-tablet {
      height: 215px;
    }
  }

  .tooltip-size-passport {
    height: 190px;

    @include medium-devices-tablet {
      height: 235px;
    }
  }
}

.document {
  &.container {
    margin-bottom: 24px;
  }

  .form-group {
    min-height: 4.5rem;
    @include medium-devices-tablet {
      min-height: 6rem;
    }
  }
  .is-invalid .invalid-feedback {
    visibility: visible;
    padding-top: 0;
    @include medium-devices-tablet {
      padding-top: 0.5rem;
    }
  }
  input {
    @include ie11support {
      font-size: 0; /* prevent cursor on input on ie11 */
    }
  }
  .is-invalid {
    & .input-group-upload {
      background: $invalidInput;
      border: 2px dashed #ff5f57;
    }
    .invalid-feedback {
      margin-top: 0.375rem;
      display: block;
    }
  }
  .summary-input {
    .is-invalid {
      & .input-group-upload {
        border: 0;
        border-bottom: 0.125rem solid $primaryErrorColor;
      }
    }
  }
}

.wizard--B {
  .file-size {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #222222;
      margin: 0;
    }
    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #9b9b9b;
    }
  }
  .input-group-upload {
    border: 1px dashed #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    max-width: unset;
    &.uploaded-image {
      background: #f5f5f5;
      border-radius: 5px;
    }
    .custom-file {
      justify-content: center;
      .upload-text-container {
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        .upload-text {
          &.upload-text-title {
            margin: 0;
          }
        }
      }
      .add-icon {
        position: relative;
        .circle {
          filter: drop-shadow(2px 2px 16px rgba(0, 0, 0, 0.15));
        }
        .vector {
          position: absolute;
          top: 50%;
          margin-top: -8px;
          left: 50%;
          margin-left: -8px;
        }
      }
    }
  }

  .summary-input {
    .form-group {
      .input-group-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        background-color: #fff;
        height: 3.875rem;
        .form-control-label {
          &.isSpecial {
            &.upload {
              display: none;
            }
          }
        }
      }
    }
  }
}

.container-summary {
  .document {
    &.container {
      margin-bottom: 0;
    }
  }
}

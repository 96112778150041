@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-black-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-black-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-blackitalic-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-blackitalic-webfont.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-bold-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-bolditalic-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-light-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-lightitalic-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-lightitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-medium-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-mediumitalic-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-mediumitalic-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-regular-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-regularitalic-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-regularitalic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-thin-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-thin-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    url("#{$font-path}/roboto-thinitalic-webfont.woff2") format("woff2"),
    url("#{$font-path}/roboto-thinitalic-webfont.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src:
    url("#{$font-path}/roboto-mono-regular.woff2") format("woff2"),
    url("#{$font-path}/roboto-mono-regular.woff") format("woff");
}

.ocr-selection-container {
  .bottom-banner-container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    &--app-ios {
      bottom: 24px;
    }
  }
}

.prohibited-to-minors-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;

  background: #f5f5f5;

  color: #929596;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.875rem;
}

.stepsbar-container {
  width: 100%;
  margin: 16px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  @include medium-devices-tablet {
    max-width: 501px;
    margin: 42px auto 0;
  }

  .step-holder {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 1000%;
      height: 1px;
      background-color: $stepBorder;
    }

    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      height: 32px;
      font-size: 12px;
      font-weight: 400;
      color: $textColor;
      border: 1px solid $stepBorder;
      border-radius: 99px;
      position: relative;
      background-color: $whiteColor;
      overflow: hidden;
      z-index: 2;

      .step-fill {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $stepFillColor;
        transition: all 0.5s;
      }

      .label-holder {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4.5px;
        img {
          width: 20px;
        }
      }
    }

    &.active-step {
      .step {
        font-weight: 500;
        color: $stepBarText;
        border-color: $stepBorderActive;
      }
    }
    &.completed-step {
      &::before {
        background-color: $stepBorderActive;
      }
      .step {
        width: 24px;
        height: 24px;
        background-color: $stepBorderActive;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .step-fill {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: $medium-devices-tablet) {
    .step-holder:not(.active-step) {
      .step {
        padding: 0;
        width: 32px;
        height: 32px;
      }
    }
  }
}

.errorCardsPageExpReg {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .cardsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 15px;

    & > *:not(:last-child) {
      margin-bottom: 36px;
    }

    @include medium-devices-tablet {
      margin-top: 50px;
      padding: 24px;
    }
  }

  #preFooter {
    margin-top: 36px;

    .contacts-options-container {
      margin-top: 36px;
    }

    @include medium-devices-tablet {
      margin-top: 0px;

      .contacts-options-container {
        margin-top: 0px;
      }
    }
  }

  .errorCardContainerReg {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding: 24px 24px;
    margin: 24px 16px 0 16px;
    box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    color: #222222;
    font-family: Roboto;
    background-color: white;
    z-index: 10;

    @include medium-devices-tablet {
      width: 500px;
    }

    .logo {
      align-self: center;
      margin-bottom: 24px;
    }

    .title {
      font-family: Muli;
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;

      &.title-large-mb {
        margin-bottom: 32px;
      }
      &.textParagraphTitle {
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 32px;

      &.text_dnc {
        font-size: 12px;
        line-height: 16px;
      }

      b {
        font-weight: 700;
      }

      a {
        color: #222222;
        text-decoration: underline;
      }

      &.advisoryText {
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        &.mr-bt {
          margin-bottom: 16px;
        }
      }
    }

    .button {
      height: 40px;
      width: 100%;
      border: none;
      border-radius: 7px;
      background-color: #bed62f;
      font-weight: 900;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      align-self: center;
      color: #222222 !important;
      justify-content: center;
      align-items: center;
      gap: 8px;

      @include medium-devices-tablet {
        height: 40px;
        width: 136px;
      }

      &.outlineButton {
        background-color: unset;
        border: 1px solid #bed62f;
      }
    }

    .recovery_link {
      margin-top: 32px;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #222222;
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 767px) {
    .button {
      width: 100%;
    }
  }
}

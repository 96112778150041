.jumio-container {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  height: calc(100vh - 3.125rem);
  display: flex;
  justify-content: center;
  align-items: center;

  .jumio-iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 0;
    box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.15);
    min-height: unset;

    @include medium-devices-tablet {
      width: 55%;
      height: 70%;
    }

    @media screen and (min-height: 550px) {
      min-height: 480px;
    }
  }
}

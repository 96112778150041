.spid-idp-button {
  ul.spid-idp-button-menu {
    height: 300px;
    overflow: auto;
  }

  img {
    box-sizing: content-box;
  }
}

.sex-select-container {
  .sex-select-input {
    background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
      right 0.55rem center/18px white;
  }

  .not-visible {
    display: none;
  }

  .sex-select-label {
    top: -0.6875rem;
    font-size: 0.75rem;
    font-weight: 500;
    background-color: white;
  }
}

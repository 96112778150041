.popup-modal-overlay {
  --transition-duration: 400ms;

  &.popup-modal-overlay-transition-enter {
    opacity: 0;

    .popup-modal {
      transform: translateY(50dvh);

      @include medium-devices-tablet {
        transform: unset;
      }
    }
  }

  &.popup-modal-overlay-transition-enter-active {
    opacity: 1;
    transition: opacity var(--transition-duration);

    .popup-modal {
      transform: translateY(0);
      transition: transform var(--transition-duration);

      @include medium-devices-tablet {
        transform: unset;
        transition: unset;
      }
    }
  }

  &.popup-modal-overlay-transition-exit {
    opacity: 1;

    .popup-modal {
      transform: translateY(0);

      @include medium-devices-tablet {
        transform: unset;
      }
    }
  }

  &.popup-modal-overlay-transition-exit-active {
    opacity: 0;
    transition: opacity var(--transition-duration);

    .popup-modal {
      transform: translateY(50dvh);
      transition: transform var(--transition-duration);

      @include medium-devices-tablet {
        transform: unset;
        transition: unset;
      }
    }
  }
}

.confirm-unmatching-data-instructions {
  margin-bottom: 24px;

  .confirm-your-data-title {
    color: #222;
    font:
      700 16px/20px "Roboto",
      sans-serif;
    margin-bottom: 8px;
  }

  .confirm-your-data-instruction-list {
    padding-left: 20px;
  }

  .confirm-your-data-instruction-element {
    color: #030303;
    font:
      400 14px/18px "Roboto",
      sans-serif;
  }
}

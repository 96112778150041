.info-privacy-disclaimer {
  margin: 0;

  .link-text,
  .mobile-link {
    text-decoration: none;

    span {
      display: block;
      font:
        10px/12px Roboto,
        sans-serif;
      letter-spacing: 0.002em;
      b {
        font-weight: bold;
      }
    }
  }
}

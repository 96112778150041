.text-separator {
  color: #222222;
  font-size: 12px;
  margin: auto;
}

.label-upload-later {
  color: #999999;
  font-size: 12px;
  line-height: 1rem;
  margin-top: -8px;
}

.label-otherwise {
  font-size: 14px;
  text-align: center;
  position: relative;
  width: 100%;
  margin: 32px 0;

  &:before {
    content: "";
    height: 3px;
    background-color: #f5f5f5;
    position: absolute;
    top: calc(50% - 1px);
    width: 100%;
    left: 0;
  }
  > span {
    position: relative;
    background-color: #fff;
    display: inline-block;
    padding: 0 12px;
  }
}

.hr-upload {
  height: 0.06rem;
  width: 3.125rem;
  border-color: $textColor;
}

.link-text {
  color: $primaryTextColor;
  font-family: Roboto;
  // text-decoration: underline;
  font-size: 0.87rem;
  font-weight: 500;
  &:hover {
    text-decoration: none;
  }
}
a:hover {
  color: $primaryTextColor;
}

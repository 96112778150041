.summary-container {
  .phone-number-block {
    display: grid;
    grid-template-columns: min-content max-content;
    align-items: center;
    row-gap: 4px;
  }

  .phone-number-block__icon {
    width: 19px;
    justify-self: end;
  }

  .phone-number-block__verified {
    color: #0b7d3e;
    font:
      500 12px Roboto,
      sans-serif;
    line-height: 14px;
    grid-column: 2 span;
  }

  .popup-contacts-body {
    padding-top: 8px;

    .header-form {
      display: none;
    }

    .contacts {
      padding: 0;
      margin: 0;

      .form-control-label {
        margin: 0;
        left: 0;
        padding-left: 5px;
        top: -0.6875rem;
        font-size: 0.75rem;
        background-color: white;
        font-weight: 500;
      }
    }
  }

  #it-ar-contacts-select-customer {
    width: 70px;
    padding-right: 0;
  }

  #it-ar-contacts-col-tel {
    flex-grow: 0;
    display: grid;
    grid-template-columns: 135px max-content;
    align-items: center;
    row-gap: 4px;
    padding-top: 10px;

    input {
      padding: 0;
      height: auto;
    }

    .success {
      grid-column: 2 span;
      margin-top: unset;
      width: unset;
    }

    .vector {
      position: unset;
      justify-self: end;
    }
  }
}

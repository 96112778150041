@import "./ocrPageBackBtn/ocrPageBackBtn";

:root {
  --jumio-sdk-font: "Roboto", sans-serif;
  --jumio-sdk-color-primary1: hsl(69, 68%, 90%);
  --jumio-sdk-color-primary2: hsl(69, 67%, 51%);
  --jumio-sdk-color-primary3: #bed630;
  --jumio-sdk-color-primary5: hsl(199, 100%, 40%);
}

.ocr-page {
  main {
    height: calc(100dvh - 50px); // fullscreen - header
  }
}

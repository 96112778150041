.questionario-pep {
  .sisal-header {
    a {
      padding: 0;
    }
    .btn-discard {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.24px;
      color: #222222;
    }
  }
  .pep-first-step {
    max-width: 550px;
    padding: 0 15px;
    margin: 0 auto;
    margin-top: 48px;
    @media screen and (max-width: 768px) {
      margin-top: 25px;
    }
    h4 {
      font-family: Muli;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.48px;
      color: #bed62f;
      margin: 0;
      margin-bottom: 5px;
    }
    > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.32px;
      color: #2f3c43;
      margin-bottom: 57px;
      @media screen and (max-width: 768px) {
        margin-bottom: 33px;
        font-size: 14px;
      }
    }
    .pep-question {
      h3 {
        font-family: Muli;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        letter-spacing: 0.48px;
        color: #222222;
        margin-bottom: 16px;
        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
      p {
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.012em;
        color: #000000;
        margin-bottom: 32px;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
        u {
          font-weight: bold;
          cursor: pointer;
        }
      }
      .pep-question-buttons {
        display: flex;
        button {
          width: 140px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.15);
          border-radius: 5px;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          letter-spacing: 0.28px;
          color: #222222;
          border: 0;
          &:first-child {
            margin-right: 48px;
            @media screen and (max-width: 768px) {
              margin-right: 32px;
            }
          }
          &:active,
          &:focus {
            background: #bed62f;
          }
        }
      }
    }
  }
  .pep-second-step {
    max-width: 550px;
    padding: 0 15px;
    margin: 0 auto;
    margin-top: 48px;
    @media screen and (max-width: 768px) {
      margin-top: 25px;
    }
    h4 {
      font-family: Muli;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.48px;
      color: #bed62f;
      margin: 0;
      margin-bottom: 5px;
    }
    > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.32px;
      color: #2f3c43;
      margin-bottom: 57px;
      @media screen and (max-width: 768px) {
        margin-bottom: 33px;
        font-size: 14px;
      }
    }
    h5 {
      font-family: Muli;
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.48px;
      color: #222222;
      margin: 0;
      margin-bottom: 16px;
    }
    .form-group {
      min-height: initial;
      margin-bottom: 32px;
    }
    .select-option {
      position: relative;
      max-width: 100%;
      border: 1px solid $borderInput;
      border-radius: 4px;
      padding: 8px 12px;
      background: url("#{$images-path}/svg/icon/arrow-down-up.svg") no-repeat
        right 0.2rem center/1.125rem 1.125rem;
      label {
        margin: 0 !important;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #2f3c43;
        width: calc(100% - 5px);
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
    .list-style {
      @media screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        z-index: 20;
        background: rgba(50, 50, 50, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .list-group {
      z-index: 9;
      max-height: initial;
      border: 0;
      background-color: #f2f2f2;
      @media screen and (max-width: 768px) {
        max-width: 540px;
        border-radius: 0.625rem;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #fff;
      }
      @media screen and (max-width: 640px) {
        max-width: initial;
        width: calc(100% - 34px);
        margin-left: 17px;
        margin-right: 17px;
      }
      li {
        border: 0;
        background-color: transparent;
        &.active {
          @media screen and (max-width: 768px) {
            background-color: #f2f2f2;
          }
        }
      }
    }
  }
  .questionario-buttons {
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-top: 20px;
    button {
      border: 0;
      box-shadow: none;
      background: none;
    }
    .indietro {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      text-decoration: underline;
      color: #222222;
      img {
        display: none;
      }
      @media screen and (max-width: 768px) {
        img {
          display: block;
        }
        span {
          display: none;
        }
      }
    }
    .continua {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #bed62f;
      border-radius: 7px;
      height: 40px;
      width: 164px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 22px;
      color: #222222;
      @media screen and (max-width: 768px) {
        width: 135px;
        img {
          display: none;
        }
      }
      &:disabled {
        opacity: 0.5;
      }
      span {
        width: 112px;
      }
    }
  }
  .questionario-footer {
    margin-top: 60px;
    margin-bottom: 32px;
    padding: 0 15px;
    .preFooter {
      margin: 0;
      // padding: 0;
    }
  }
}

header {
  border-bottom: 1px solid $stepBorder;
  position: relative;
  z-index: 1;

  &.sisal-header {
    background: #ffffff;
    height: 3.125rem;
    display: flex;
    align-items: center;

    .m-visible {
      display: none;
    }

    .d-visible {
      display: block;
    }

    //mod progressBarDots
    &.sisal-header--A,
    &.sisal-header--B {
      border-bottom: 1px solid $stepBorder;
    }

    @include medium-devices-tablet {
      // margin-left: 0.625rem;
      // margin-right: 0.625rem;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }

    .logo {
      width: 5.5rem;
      height: 2rem;
    }

    .btn-discard {
      color: #222222;
      font-size: 0.75rem;
      font-weight: 700;
      @include medium-devices-tablet {
        font-size: 0.875rem;
      }
    }
  }
}

@media screen and (max-height: 568px) {
  .prefooterRect-parent-div-flussoB {
    position: relative;
    height: 72px;

    &.h-none {
      height: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  header {
    &.sisal-header {
      .m-visible {
        display: block;
      }

      .d-visible {
        display: none;
      }
    }
  }

  .leftRect:after {
    width: 120px;
    height: 150px;
    margin-right: 0;
  }

  .prefooterRect::after {
    width: 120px;
    height: 200px;
    background-size: contain;
  }

  .prefooterRect-parent-div-flussoB {
    .bigMarginPreFooter {
      margin-bottom: 0;
    }

    &.h-none {
      height: 0;
    }
  }
}

.summary-container {
  .popup-document-body {
    .header-form {
      display: none;
    }

    .identification,
    .ocrForm {
      padding: 0;
    }

    .identification {
      overflow: hidden;
    }

    #it-ar-identification-form-group1 {
      margin: 0;
      padding-bottom: 8px;
    }

    .tooltip-container {
      top: 44px;
      padding: 15px;
      width: calc(100% + 15px);
      left: 50%;
      transform: translateX(-50%);

      .img-triangle {
        top: -15px;
        left: auto;
        transform: rotate(90deg);
      }

      .img-triangle-no-warning {
        right: 23px;
      }

      .img-triangle-warning {
        right: 53px;
      }

      .img-close {
        display: block;
      }

      .img-documentId-type {
        left: auto;
        right: 16px;
        border-radius: 10px;
      }

      .tooltip-info {
        width: 35%;
      }

      .bottom-desc {
        width: 35%;
      }
    }

    .tooltip-size-idCard {
      height: 170px;
    }

    .tooltip-size-driver-license {
      height: 170px;
    }

    .tooltip-size-passport {
      height: 190px;
    }
  }
}

.spid-fc {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .spid-fc-container {
    margin: auto;
    max-width: 532px;
    padding: 0 16px;
    flex-grow: 1;

    .spid-fc-card {
      margin-top: 56px;
      padding: 32px 24px;
      border: 1px solid #f0f2f4;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04);
      border-radius: 15px;
      background-color: #fff;
      color: $textSelectColor;
      font-size: 16px;
      line-height: 20px;

      @include medium-devices-tablet {
        margin-top: 15vh;
        padding-top: 48px;
        padding-bottom: 48px;
        font-size: 14px;
        line-height: 18px;
      }

      .spid-fc-card-title {
        margin-bottom: 16px;
        font-family: Muli;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
      }

      .spid-fc-card-text {
        margin-bottom: 16px;
      }

      .spid-fc-card-form {
        display: flex;
        flex-direction: column;

        .form-group {
          margin-bottom: 24px;
          min-height: unset;

          .form-control {
            &.is-required {
              border-color: #fabb00;

              ~ .form-control-label {
                color: #fabb00;
                font-weight: 500;
              }
            }
            &.is-invalid {
              border-color: #e32219;

              ~ .form-control-label {
                color: #e32219;
                font-weight: 500;
              }
            }
          }

          .feedback-container {
            .invalid-feedback {
              display: block;
              padding: 0;
              padding-left: 5px;
              margin-top: 4px !important;
              margin-bottom: 0;
              min-height: unset;
              line-height: 14px;
            }

            &.is-required .invalid-feedback {
              color: #fabb00;
            }
          }
        }

        .spid-fc-card-input {
          margin-bottom: 24px;
        }

        .spid-fc-card-button {
          padding: 12px 0;
          border: none;
          border-radius: 8px;
          background-color: #bed62f;
          color: #262c2f;
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;

          &:disabled {
            background-color: #deea96;
            color: #929596;
          }
        }
      }
    }
  }

  .spid-fc-card-skip-fc-check-button {
    margin-top: 1rem;
    width: 100%;
    color: #262c2f;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    text-decoration: underline;
    border: none;
    background-color: unset;
    padding: 0;
  }

  .spid-fc-footer--app-ios {
    padding-bottom: 24px;
  }

  .spid-fc-privacy-disclaimer {
    padding-top: 58px;
  }
}

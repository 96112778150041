.form-group {
  .form-control {
    &.is-present {
      ~ .invalid-alert {
        color: $textAlreadyPresent;
        font-weight: 500;
        padding-left: 0.31rem;
        padding-top: 0.3rem;
        visibility: visible;
        font-size: 12px;
      }
    }
    ~ .invalid-alert {
      color: $primaryErrorColor;
      font-weight: 500;
      padding-left: 0.31rem;
      padding-top: 0.3rem;
      visibility: visible;
      font-size: 12px;
    }
  }
}

#success-modal-loyalty {
  .overlay.popup-modal-overlay {
    .popup-modal-body {
      padding: 16px 16px 16px;
      overflow: auto;

      h6 {
        font-family: Roboto, sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #030303;
        line-height: 20px;
      }

      p {
        font-family: Roboto, sans-serif;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
      }

      .popupmodal-img {
        display: flex;
        justify-content: center;
        height: fit-content;
        margin-bottom: 10px;

        img {
          align-items: center;
        }
      }
    }

    .popup-modal-footer {
      #close-loyalty-btn {
        background-color: #ffffff;
        border: 1px solid #bed62f;

        @media screen and (min-width: 601px) {
          width: 150px;
        }
      }
    }
  }
}
